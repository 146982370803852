import { useState, useEffect, useRef, useCallback } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import getWindowDimensions from "../../hooks/getWindowDimensions";
import { useInterfaceContext } from "../../hooks/InterfaceDetails";
import DisplayPdf from "../PDF/DisplayPdf";
import interfaceData from "../../data.json";
import styles from "./articlesComponent.module.css";

const FullViewArticle = () => {
  const { id } = useParams();
  const interfaceId = interfaceData.data.interface_id;

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    // "App-Origin": sessionStorage.getItem("interface_id"),
    "App-Origin": interfaceId,
    "Content-Type": "application/json",
  };

  const interfaceDetails = useInterfaceContext();

  const [article, setArticle] = useState();

  const [url, setUrl] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [videoUrl, setVideoUrl] = useState();

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    (async () => {
      await getPatientArticle();
    })();

    // setUrl(props?.fullViewArticle?.final_link);
    // setVideoUrl(props?.fullViewArticle?.raw_content_path);
    // setImageUrl(props?.fullViewArticle?.final_link);}

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const decodeEntities = (function () {
    const element = document.createElement("textarea");
    function decodeHTMLEntities(str) {
      if (str && typeof str === "string") {
        // str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.value;
        element.innerHtml = "";
      }
      return str;
    }

    return decodeHTMLEntities;
  })();
  const getPatientArticle = async () => {
    const res = await fetch(
      baseUrl +
        `patient-website/get-article-details?id=${id}&interface_id=${interfaceId}`,
      {
        method: "GET",
        headers,
      }
    );

    const data = await res.json();

    console.log(data);

    if (data?.response) {
      setArticle(data.response);
    }

    // if (
    //   interfaceDetails?.getArticles?.data &&
    //   interfaceDetails?.getArticles?.data.length > 0
    // ) {
    //   const [res] = interfaceDetails.getArticles.data.filter((data) => {
    //     return data.id === parseInt(id);
    //   });
    //   console.log(res);
    //   setArticle(res);
    //   setUrl(res?.final_link || "");
    // }
  };

  // console.log("article data" + JSON.stringify(url));

  return (
    <>
      <div className={styles["fullview-container"]}>
        <div className="fullView_article_title">
          <h4
            className="article_title"
            style={{ textAlign: "center", marginBottom: "1em" }}
          >
            {/* {props?.fullViewArticle?.content_title} */}
            {article?.content_title}
          </h4>

          <p
            className="article_discription"
            dangerouslySetInnerHTML={{
              __html: decodeEntities(article?.content_desc),
            }}
            style={{ marginBottom: "2em" }}
          ></p>

          {/* <p className="article_author">{props?.fullViewArticle?.user}</p> */}
          {article?.final_link && (
            <p className="article_author" style={{ fontSize: "14px" }}>
              {article?.user}
            </p>
          )}
        </div>

        <>
          {/* {"props?.fullViewArticle?.content_type" === "pdf" && ( */}
          {article?.content_type === "pdf" && (
            <>
              {windowDimensions.width < 768 ? (
                <div className="iframe-loader" style={{ height: "350px" }}>
                  <iframe
                    id="iframeContainer"
                    src={`https://drive.google.com/viewerng/viewer?url=${article?.final_link}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                    width="100%"
                    height="350px"
                  />
                </div>
              ) : (
                <DisplayPdf
                  // url={props?.fullViewArticle?.final_link}
                  url={article?.final_link}
                  width={980}
                  height={380}
                />
              )}
            </>
          )}
        </>
        <>
          {/* {props?.fullViewArticle?.content_type == "article" && ( */}
          {article?.content_type == "article" && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* {props?.fullViewArticle?.final_link !== "" && ( */}
              {article?.final_link !== "" && (
                <img
                  // src={props?.fullViewArticle?.final_link}
                  src={article?.final_link}
                  alt="image"
                  style={{ width: "50%" }}
                />
              )}
            </div>
          )}
        </>

        {article?.content_type === "article" && (
          <div className={styles["article-content"]}>
            {/* {article?.header_img !== "" && (
              <img
                src={article?.final_link}
                alt=""
                style={{ height: "250px", width: "100%" }}
              />
            )} */}

            {article?.content_value !== "" && (
              <p
                // className="article_text"
                dangerouslySetInnerHTML={{
                  __html: decodeEntities(article.content_value),
                }}
              ></p>
            )}
          </div>
        )}

        <>
          {/* {props?.fullViewArticle?.content_type === "video" && ( */}
          {article?.content_type === "video" && (
            <ReactPlayer
              // url={props?.fullViewArticle?.final_link}
              url={article?.final_link}
              width="99%"
              controls
            />
          )}
        </>
      </div>
    </>
  );
};

export default FullViewArticle;
