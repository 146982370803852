import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ReactPlayer from "react-player";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useInterfaceContext } from "../../hooks/InterfaceDetails";
import interfaceData from "../../data.json";
import Spinner from "../Spinner";
const Images = ({ sectionData, ondisplayName }) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const headers = {
        "App-Origin": sessionStorage.getItem("interface_id"),
        "Content-Type": "application/json",
    };
    const [galleryData, setGalleryData] = useState([]);
    const [spinner, setSpinner] = useState(false);
    // const { setImagePgNo } = useInterfaceContext();
    const [data, setData] = useState(sectionData);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState(false);
    const [imageData, setImageData] = useState();

    useEffect(() => {
        getGalleryDetails();
      }, [page]);

    const handleChange = (event, value) => {
        // setImagePgNo(value);
        setPage(value);
    };

    const handleOpen = (image) => {
        setImageData(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const getGalleryDetails = async () => {
        const interfaceId = interfaceData.data.interface_id;
        setSpinner(true);
        const res = await fetch(
            baseUrl +
            `patient-website/get-gallery-images?interface_id=${encodeURIComponent(interfaceId )}&images_page=${encodeURIComponent(page)}`,
            {
                method: "GET",
                headers: headers,
            }
        );
        const data = await res.json();

        if (data.response != null) {
            //   const sections = data?.response?.images;
            setGalleryData(data?.response?.images);


        }
        setSpinner(false);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row no-gutters">
                    {spinner ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spinner />
                        </div>

                    ) : (
                        galleryData?.data?.map((item, index) => (
                            <>
                                <div className="col-lg-4 col-md-4">
                                    <div className="section-title">
                                        <p>{item?.file_title}</p>
                                    </div>
                                    <div style={{ border: '1px solid black', minHeight: '300px', maxHeight: '350px', marginTop:'20px' }}>
                                        <div className="gallery-image"
                                            onClick={(e) => handleOpen(item)}>
                                            <img src={item?.file_url} alt="" className="img-fluid" style={{ minHeight: '300px', maxHeight: '300px', width : "100%" }} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )))}
                </div>

                {galleryData?.total > 6 && (<div className="pagination mt-3">
                    <Stack spacing={2}>
                        <Pagination siblingCount={0} count={galleryData?.last_page} showFirstButton showLastButton page={page} onChange={handleChange} />
                    </Stack>
                </div>)}

            </div>
            <Modal classNames={{ modal: "image_model" }}
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description" >
                {/* <h2 id="parent-modal-title">{imageData?.file_title}</h2> */}
                <p id="parent-modal-description">
                    <div className="gallery-image" >
                        <img src={imageData?.file_url} alt="image" className="image_container" />
                    </div>
                </p>
            </Modal>
        </>
    );
}
export default Images;