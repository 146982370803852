import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

const Video = ({pauseOtherVideos, setPauseOtherVideos, handleOpen, item}) => {
    const [videoPlaying, setVideoPlaying] = useState(false);


    useEffect(() => {
        if (videoPlaying) {
          setVideoPlaying(false);
        }
      }, [pauseOtherVideos]);

    const handlePlay = () => {
        setPauseOtherVideos((prev) => !prev);
        setVideoPlaying(true);
      };
    
      const handlePause = () => {
        setVideoPlaying(false);
      };


    return (
      <div className="col-lg-4 col-md-4 mt-4">
        <div
          style={{
            background: "#fff",
            height: "320px",
            border: "1px solid #000",
          }}
        >
          <h1 className="testimonial-name">{item?.file_title}</h1>
          <div className="readMore-container px-2">
            <p style={{ cursor: "pointer" }} onClick={(e) => handleOpen(item, setVideoPlaying(false))}>
              <u>Read More</u>
            </p>
          </div>
          <div className="gallery-item">
            <ReactPlayer
              url={item?.file_url}
              playing={videoPlaying}
              style={{ maxHeight: "200px" }}
              width="99%"
              controls
              onPlay={handlePlay}
              onPause={handlePause}
            />
          </div>
        </div>
      </div>
    );
};

export default Video;