import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./DoctorsList.module.css";
import { Modal } from "react-responsive-modal";
import { useInterfaceContext } from "../../hooks/InterfaceDetails";
import getWindowDimensions from "../../hooks/getWindowDimensions";
import { height } from "@mui/system";

const DoctorsList = ({ sectionData }) => {
  const interfaceDetails = useInterfaceContext();
  const [color, setColor] = useState("");
  const [home] = interfaceDetails.data.filter(
    (section) => section.section_id === "home"
  );
  const [noOfDoctors, setNoOfDoctors] = useState(4);

  const inputDivStyle = {
    border: "1px solid",
    borderColor: home?.home_theme_color,
    color: home?.home_theme_color,
    fontSize: "13px",
    display: "flex",
    fontWeight: "600",
    alignItems: "center",
    cursor: "pointer",
  };
  const [open, setOpen] = useState(false);
  const [doctorData, setDoctorData] = useState();
  const [mobile, setMobile] = useState(false);
  const onCloseModal = () => {
    setOpen(false);
  };

  const openDoctorData = (data) => {
    setDoctorData(data);
    setOpen(true);
  };
  const changleColor = (e, backgroundColor, color) => {
    e.target.style.backgroundColor = backgroundColor;
    e.target.style.color = color;
  };

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );
  const [result] = useState(() => isMobile())
  function isMobile() {
    if (window.innerWidth < 700) {
     return setMobile(true); ;
		} else {
			return setMobile(false);
    }
  }

  
  console.log("isMobileDevice>>>>>>>"+mobile);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return (
    <>
      <section style={{ marginBottom: "1em" }} className="doctors">
        <div className="container">
          <div className="section-title services">
            <h2
              style={{
                color: home?.home_theme_color,
                fontWeight: 700,
                margin: "20px",
                fontFamily: home?.home_font_family,
                textAlign: "center",
              }}
            >
              {sectionData?.multi_doctor_section_title}
            </h2>
          </div>

          <div
            className={styles.container}
            style={{
              gridTemplateColumns:
                windowDimensions.width < 768
                  ? "repeat(auto-fit , 400px)"
                  : "repeat(auto-fit , 480px)",
            }}
          >
            {sectionData?.doctors
              ?.slice(0, noOfDoctors)
              .map((doctor, index) => (
                <div
                  className={styles.doctors}
                  style={{
                    padding: windowDimensions.width < 768 ? "0px" : "1em",
                    cursor: "pointer",
                  }}
                  onClick={(e) => openDoctorData(doctor)}
                  onMouseOver={(e) => {
                    let button = document.getElementById(
                      `viewMore${doctor.id}`
                    );
                    button.style.backgroundColor = home?.home_theme_color;
                    button.style.color = "#fff";
                  }}
                  onMouseOut={(e) => {
                    let button = document.getElementById(
                      `viewMore${doctor.id}`
                    );
                    button.style.backgroundColor = "#fff";
                    button.style.color = home?.home_theme_color;
                  }}
                  key={index}
                >
                  <div className="member d-flex align-items-start">
                    <div
                      style={{
                        width: windowDimensions.width < 768 ? "105px" : "25%",
                        height: windowDimensions.width < 768 ? "95px" : "25%",
                      }}
                      className="pic mt-3"
                    >
                      <img
                        src={doctor.profile_pic}
                        style={{ width: "120px" ,height:"100px"}}
                       // class="rounded-circle"
                      />
                    </div>
                    <div style={{ width: "75%", marginLeft: "10px" }}>
                      <div
                        style={{
                          fontSize: doctor.name_px,
                          fontWeight: "bold",
                          overflowWrap: "break-word",
                          height: "auto",
                          
                        }}
                        dangerouslySetInnerHTML={{ __html: doctor.name }}
                      ></div>
                      {doctor?.qualification && doctor?.qualification !== "" && (
                        <div className="doctor_qualification"
                          style={{
                            fontSize: doctor.qualification_px,
                            fontWeight: "bold",
                            overflowWrap: "break-word",
                            height: "45px",
                            
                            
                          }}
                          dangerouslySetInnerHTML={{
                            __html: doctor.qualification,
                          }}
                        ></div>
                      )}
                      {doctor.designation && doctor?.designation !== "" && (
                        <div
                          style={{
                            flexGrow: 1,
                            marginTop: "10px",
                            
                            fontSize: doctor.designation_px,
                            fontWeight: "700",
                            color: "#837d7d",
                            overflowWrap: "break-word",
                            height: "45px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: doctor.designation,
                          }}
                        ></div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "30%",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <button
                            type="button"
                            className="btn"
                            onClick={(e) => openDoctorData(doctor)}
                            id={`viewMore${doctor.id}`}
                            style={inputDivStyle}
                          >
                            View More Info
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {sectionData?.doctors?.length > 4 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              type="button"
              className="btn btn-primary view-button"
              style={{
                background: home?.home_theme_color,
                border: "1px solid",
                borderColor: home?.home_theme_color,
                color: "white",
                fontSize: "18px",
              }}
              onClick={() => {
                if (noOfDoctors <= 4)
                  setNoOfDoctors(sectionData?.doctors?.length);
                else setNoOfDoctors(4);
              }}
            >
              {noOfDoctors <= 4 ? "View More Doctors" : "View Less Doctors"}
            </button>
          </div>
        )}
      </section>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "doctorModel",
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          // modalAnimationIn: 'customEnterModalAnimation',
          // modalAnimationOut: 'customLeaveModalAnimation',
        }}
        styles={{
          closeButton: { right: "8px", top: "8px" },
        }}
        animationDuration={500}
      >
        <div className="custom-modal">
          <div className="modal-body">
            <div className="card-head">
              <div className="card">
                <div className="card-body">
                  <div
                    className="card-container"
                    style={{
                      width: windowDimensions.width < 768 ? "260px" : "1200px",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="card-pic"
                      style={{
                        width: windowDimensions.width < 768 ? "18%" : "10%",
                        float: "left",
                      }}
                    >
                      <div className="pic mt-3">
                        <img
                          src={doctorData?.profile_pic} 
                          style={{
                            borderRadius: "50%",
                            width:
                              windowDimensions.width < 768 ? "80px" : "105px",
                            height:
                              windowDimensions.width < 768 ? "80px" : "102px",
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="card-title">
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: doctorData?.name_px,
                          width:"60%",
                        }}
                        dangerouslySetInnerHTML={{ __html: doctorData?.name }}
                      ></div>
                      <div
                        style={{
                          color: "gray",
                          fontSize: doctorData?.qualification_px,
                          width:
                              windowDimensions.width < 768 ? "100%" : "60%",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: doctorData?.qualification,
                        }}
                      ></div>
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: doctorData?.designation_px,
                          width:"60%",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: doctorData?.designation,
                        }}
                      ></div>

                      <br />
                      <div className="d-none d-lg-block">
                        <h6 style={{ fontWeight: "700", fontSize: "12px" }}>
                          About Me
                        </h6>
                        <div
                          style={{
                            width: "690px",
                            fontSize: doctorData?.about_doctor_px,
                            color: "gray",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: doctorData?.about_doctor,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="d-block d-lg-none"
                  style={{ marginTop: "-3px", marginLeft: "25px" }}
                >
                  <h6
                    className=""
                    style={{ fontWeight: "700", fontSize: "12px" }}
                  >
                    About Me
                  </h6>
                  <p
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(auto-fit , 255px)",
                      maxWidth: "350px",
                      fontSize: doctorData?.about_doctor_px,
                      color: "gray",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: doctorData?.about_doctor,
                    }}
                  ></p>
                </div>
              </div>
            </div>
            {(doctorData?.dr_practices.length != 0 ||
              doctorData?.dr_services.length != 0) && (
              <div id="SnehaLatha" className="tabcontent">
                {doctorData?.dr_practices.length != 0 && (
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img
                      src="assets/img/ic_hospital_grey.svg"
                      className="img-fluid"
                      alt=""
                    />
                    <h3 style={{ fontWeight: "700", marginLeft: "12px" }}>
                      I Practice At
                    </h3>
                  </div>
                )}
                {doctorData?.dr_practices.map((practices) => (
                  <div
                    style={{
                      border: "1px solid #403e3e",
                      borderRadius: "10px",
                      padding: "25px",
                      marginBottom: "10px",
                    }}
                  >
                    <div style={{ display: "flex", gap: "0em" }}>
                      <div style={{ width: "7%", marginTop: "5px" }}>
                        <img
                          src="assets/img/ic_location_grey.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div style={{ width: "90%", marginTop: "5px" }}>
                        <h6 className="doctor-contact-details">
                          <b>Contact Details</b>
                        </h6>
                        <div
                          className="doctor-contact-details"
                          style={{
                            fontSize: practices?.text_px,
                            wordBreak: "break-word",
                          }}
                          dangerouslySetInnerHTML={{ __html: practices?.text }}
                        ></div>
                      </div>
                    </div>
                    <hr />
                    <h6 className="doctor-contact-details">
                      <b>My Working Hours</b>
                    </h6>
                    <div
                      style={{
                        display: "flex",
                        gap: "0em",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30%",
                          marginTop: "5px",
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        Monday
                        <div style={{ fontWeight: "600", color: "black" }}>
                          {practices?.monday_1 != "" ||
                          practices?.monday_2 != "" ? (
                            <>
                              {practices?.monday_1} <br /> {practices?.monday_2}
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "30%",
                          marginTop: "5px",
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        Tuesday
                        <div style={{ fontWeight: "600", color: "black" }}>
                          {practices?.tuesday_1 != "" ||
                          practices?.tuesday_2 != "" ? (
                            <>
                              {practices?.tuesday_1} <br />{" "}
                              {practices?.tuesday_2}
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "30%",
                          marginTop: "5px",
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        Wednesday
                        <div style={{ fontWeight: "600", color: "black" }}>
                          {practices?.wednesday_1 != "" ||
                          practices?.wednesday_2 != "" ? (
                            <>
                              {practices?.wednesday_1} <br />{" "}
                              {practices?.wednesday_2}
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "2em",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30%",
                          marginTop: "5px",
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        ThursDay
                        <div style={{ fontWeight: "600", color: "black" }}>
                          {practices?.thursday_1 != "" ||
                          practices?.thursday_2 != "" ? (
                            <>
                              {practices?.thursday_1} <br />{" "}
                              {practices?.thursday_2}
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "30%",
                          marginTop: "5px",
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        Friday
                        <div style={{ fontWeight: "600", color: "black" }}>
                          {practices?.friday_1 != "" ||
                          practices?.friday_2 != "" ? (
                            <>
                              {practices?.friday_1} <br /> {practices?.friday_2}
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "30%",
                          marginTop: "5px",
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        Saturday
                        <div style={{ fontWeight: "600", color: "black" }}>
                          {practices?.saturday_1 != "" ||
                          practices?.saturday_2 != "" ? (
                            <>
                              {practices?.saturday_1} <br />{" "}
                              {practices?.saturday_2}
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: "2em",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30%",
                          marginTop: "5px",
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        Sunday
                        <div style={{ fontWeight: "600", color: "black" }}>
                          {practices?.sunday_1 != "" ||
                          practices?.sunday_2 != "" ? (
                            <>
                              {practices?.sunday_1} <br /> {practices?.sunday_2}
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {doctorData?.dr_services.length != 0 && (
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img
                      src="assets/img/ic_service_grey.svg"
                      className="img-fluid"
                      alt=""
                    />
                    <h3 style={{ fontWeight: "700", marginLeft: "12px" }}>
                      My Services
                    </h3>
                  </div>
                )}
                {doctorData?.dr_services.map((services, index) => (
                  <>
                    <div style={{ display: "flex", marginLeft: "2em" }}>

               {!mobile &&
                      <span
                        style={{ width: "6%", fontSize: services?.name_px }}
                      >
                        {index + 1}.
                      </span>
               } {mobile && ((index + 1)<=9) &&
                <span
                  style={{ width: "6%", fontSize: services?.name_px }}
                >
                  {index + 1}.
                </span>
               }
               {mobile && ((index + 1)>9) &&
                <span
                  style={{ width: "8%", fontSize: services?.name_px }}
                >
                  {index + 1}.
                </span>
               }
         
               
                      &nbsp;
                      <span
                        style={{
                          width: "92%",
                          fontSize: services?.name_px,
                          wordBreak: "break-word",
                        }}
                        dangerouslySetInnerHTML={{ __html: services?.name }}
                      ></span>
                    </div>
                    <hr
                      className="service_title hr_tag"
                      style={{ marginTop: "0px" }}
                    />
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DoctorsList;
