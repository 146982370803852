
import styles from './AppSection.module.css';
import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from "react";
import { useInterfaceContext } from '../../hooks/InterfaceDetails';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Address from '../Address/Address';
import "react-responsive-modal/styles.css";
import { color, display, height, textAlign } from '@mui/system';
import QRCode from "qrcode.react";



const NameContext = React.createContext();

const AppSectionDeatils = ({ sectionData }) => {
  const interfaceDetails = useInterfaceContext();
  const [value, setValue] = React.useState(0);
  
  const [mobile, setMobile] = useState(false);

  const [home] = interfaceDetails?.data?.filter(
    (section) => section.section_id === "home"
  );
  
  // const [appSection] = interfaceDetails?.data?.filter(
  //   (section) => section.section_id === "patientapp"
  // );


 

  const decodeEntities = (function () {
    const element = document.createElement("textarea");
    function decodeHTMLEntities(str) {
      if (str && typeof str === "string") {
        // str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.value;
        element.innerHtml = "";
      }
      return str;
    }

    return decodeHTMLEntities;
  })();

  const [result] = useState(() => isMobile())
  function isMobile() {
    if (window.innerWidth < 700) {
     return setMobile(true); ;
		} else {
			return setMobile(false);
    }
  }
  console.log("isMobileDevice<<<<<<<<<<<<>>>>>>>"+mobile);

  return (sectionData?.app_section_status == 1 && sectionData?.app_separate_section==1 && 
    <>
    {  sectionData?.app_section_status == 1 ? (
          <div class="section-title">
            <h2
              style={{
                color: home?.home_theme_color,
                // fontSize: "32px",
                fontWeight: 700,
                fontFamily: home?.home_font_family,
              }}
            >
              { "App Section"}
            </h2>
          </div>
        ) : null}
    { !mobile && sectionData?.app_separate_section==1 &&
    <>

<div>
  <div class="row">
      <div class="col col-md-6" style={{minHeight:"100%",paddingRight:"0px",background: home?.home_theme_color, fontFamily: home?.home_font_family,paddingBottom:"10px"}}>
        <div style={{background: home?.home_theme_color, fontFamily: home?.home_font_family,textAlign:"center" , height : '100%'}} >
          
        <p dangerouslySetInnerHTML={{ __html:sectionData?.app_banner }}  style={{background: home?.home_theme_color, fontFamily: home?.home_font_family,textAlign:"center",fontSize:sectionData?.app_banner_px,color:'#fff',paddingTop:"20px",wordBreak:"break-word"}}
         ></p>
         <p dangerouslySetInnerHTML={{ __html:sectionData?.app_sub_title }} style={{background: home?.home_theme_color, fontFamily: home?.home_font_family,textAlign:"center",fontSize:sectionData?.app_sub_title_px,color:'#fff',paddingTop:"7px",wordBreak:"break-word"}} 
         ></p>
         <p style={{background: home?.home_theme_color, fontFamily: home?.home_font_family,paddingTop:"15px", justifyContent: "center",display: "flex"}} >

         <button class="btn" onClick={() => window.open(sectionData?.app_section_link , "_blank")} style={{backgroundColor:"black",height:"50px",paddingBottom:"10px",display:"flex",width:"160px",alignItems:"center",justifyContent:"space-evenly",gap:"0.6em"}}>
               
         <img width="30" height="30" src="assets/img/playstore-icon.png" alt="google play" loading="lazy" data-was-processed="true"/>
        
         <div style={{display : 'flex' , flexDirection : "column"}}>

         <span style={{color:"white",fontSize:"10px",maxWidth:"150px"}}> GET IT ON </span> 
 
         <span  style={{color:"white",fontSize:"15px",textAlign:"left",lineBreak:"anywhere"}}> Google Play</span>
        
         </div>
        
         </button>
          {/* <span style={{verticalAlign:"bottom"}}><QRCode value={sectionData?.app_section_link}  style={{marginLeft: "15px",width:"55px",height:"45px" }} /></span> */}
          </p>
        </div>
      </div>
      { sectionData?.app_background_image!=null && sectionData?.app_background_image!="" && (
      <div class="col col-md-6" style={{minHeight:"100%",paddingRight:"0px",backgroundImage:`url("${sectionData?.app_background_image}")`,minHeight:"100%",backgroundPosition: 'center',backgroundSize: 'cover',groundRepeat: 'no-repeat'}}>
       <p style={{
            display: "flex",
            margin:"10%",
            justifyContent: "center",
            alignItems: "center",
          }}><img class="rounded"  style={{width:"120px",height:"120px",alignItems:"center"}} src={`${sectionData?.app_icon}`}  />  </p>
      </div>
      )
    }
      { sectionData?.app_background_image==null && (
      <div class="col col-md-6" style={{background: home?.home_theme_color+"40", fontFamily: home?.home_font_family,textAlign:"center" , minHeight : '100%'}}>
      
       <p  style={{
            display: "flex",
            margin:"10%",
            justifyContent: "center",
            alignItems: "center",
          
          }}><img class="rounded" style={{width:"120px",height:"120px"}} src={`${sectionData?.app_icon}`}  /></p>
      
      </div>
      )
    }
      
  </div>
</div>

      </>
}

{ mobile && sectionData?.app_separate_section==1 &&
    <>
      
<div class="container">
 
  { sectionData?.app_background_image!=null && sectionData?.app_background_image!="" && (
      <div class="row" style={{minHeight:"100%",backgroundImage:`url("${sectionData?.app_background_image}")`,minHeight:"100%",backgroundPosition: 'center',backgroundSize: 'cover',groundRepeat: 'no-repeat'}}>
       <p style={{
            display: "flex",
           margin:"3%",
            justifyContent: "center",
            alignItems: "center",
          }}><img class="rounded"  style={{width:"120px",height:"120px"}} src={`${sectionData?.app_icon}`}  />  </p>
      </div>
  )}

{ sectionData?.app_background_image==null  && (
      <div class="row" style={{background: home?.home_theme_color+"40",minHeight:"100%",paddingRight:"0px",maxHeight:"100%",textAlign:"center" , minHeight : '100%'}}>
       <p style={{
            display: "flex",
           margin:"3%",
            justifyContent: "center",
            alignItems: "center",
          }}><img class="rounded"  style={{width:"120px",height:"120px"}} src={`${sectionData?.app_icon}`}  />  </p>
      </div>
  )}
      <div class="row" style={{minHeight:"100%",paddingRight:"0px",background: home?.home_theme_color, fontFamily: home?.home_font_family}}>
        <div style={{background: home?.home_theme_color, fontFamily: home?.home_font_family,textAlign:"center" , height : '100%'}} >
          
        <p dangerouslySetInnerHTML={{ __html:sectionData?.app_banner }}  style={{background: home?.home_theme_color, fontFamily: home?.home_font_family,textAlign:"center",fontSize:sectionData?.app_banner_px,color:'#fff',paddingTop:"20px",paddingBottom:"15px",wordBreak:"break-word"}}
         ></p>
         <p dangerouslySetInnerHTML={{ __html:sectionData?.app_sub_title }} style={{background: home?.home_theme_color, fontFamily: home?.home_font_family,textAlign:"center",fontSize:sectionData?.app_sub_title_px,color:'#fff',paddingBottom:"10px",wordBreak:"break-word"}} 
         ></p>
         <p style={{background: home?.home_theme_color, fontFamily: home?.home_font_family,alignItems:"center",paddingBottom:"15px",display: "flex"}} >
         
         <button class="btn" onClick={() => window.open(sectionData?.app_section_link , "_blank")} style={{backgroundColor:"black",height:"50px",paddingBottom:"10px",display:"flex",width:"160px",alignItems:"center",justifyContent:"space-evenly",gap:"0.6em",marginLeft:"100px"}}>
               
         <img width="30" height="30" src="assets/img/playstore-icon.png" alt="google play" loading="lazy" data-was-processed="true"/>
        
         <div style={{display : 'flex' , flexDirection : "column"}}>

         <span style={{color:"white",fontSize:"10px",maxWidth:"150px"}}> GET IT ON </span> 
 
         <span  style={{color:"white",fontSize:"15px",textAlign:"left",lineBreak:"anywhere"}}> Google Play</span>
        
         </div>
        
         </button>
          {/* <span style={{verticalAlign:"bottom"}}><QRCode value={sectionData?.app_section_link} style={{marginLeft: "15px",width:"55px",height:"45px" }} /></span> */}
          </p>
        </div>
      </div>
      
 
</div>
      </>
    }

</>
);
}


export default AppSectionDeatils;
