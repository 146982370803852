import React, { useEffect, useRef, useState } from "react";
import styles from "./AboutProfile.module.css";
import { useInterfaceContext } from "../../../../hooks/InterfaceDetails";
import { themeColor, logo, font } from "../../../../Utils/Utils";
import clip from "text-clipper";

import { AiOutlineCloseCircle } from "react-icons/ai";

import { Modal } from "react-responsive-modal";
const AboutProfile = ({ props, sectionData }) => {
  const descriptionRef = useRef();

  const [open, setOpen] = useState(false);
  const [doctorData, setDoctorData] = useState();
  const onCloseModal = () => {
    setOpen(false);
  };

  const openDoctorData = (data) => {
    setDoctorData(data);
    setOpen(true);
  };
  const interfaceDetails = useInterfaceContext();

  const [isReadMore, setIsReadMore] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);

  const [font_Family, setFont_Family] = useState(font);

  const [specializationExperience, setSpecializationExperience] = useState(props);

  const [discription, setDiscription] = useState(
    props?.doctors?.aboutUs?.description
    );

  const descriptionHeight = {
    10: `${sectionData?.about_desc_px * 1.5 * 26}px`,
    12: `${sectionData?.about_desc_px * 1.5 * 22}px`,
    14: `${sectionData?.about_desc_px * 1.5 * 18}px`,
    16: `${sectionData?.about_desc_px * 1.5 * 16}px`,
  };

  const theme_color = interfaceDetails?.data
    ?.filter((index) => index.section_id === "home")
    .map((index) => {
      return index;
    });

  useEffect(() => {
    setShowReadMore(isOverflown());
  }, []);

  const addReadmore = (htmlString) => {
    if (!htmlString || htmlString === "") return "";

    const clippedString = clip(htmlString, 1000, {
      html: true,
      // maxLines: 12,
      breakWords: true,
    });

    return clippedString;
  };

  const isOverflown = () => {
    if (descriptionRef.current)
      return (
        descriptionRef.current.scrollHeight >
        descriptionRef.current.clientHeight + 10
      );
    else return false;
  };

  // return (
  //   <>
  //     <div style={{ padding: "1em" }}>
  //       <div className={`${styles["about-img"]}`}>
  //         <img
  //           style={{ width: "100%", height: "100%" }}
  //           src={sectionData?.about_image}
  //         />
  //       </div>
  //       <div className={`${!isReadMore && styles["about-us-container"]}`}>
  //         <h3
  //           style={{
  //             color: `${theme_color[0]?.home_theme_color}`,
  //             fontWeight: 700,
  //             fontFamily: `${font_Family}`,
  //           }}
  //         >
  //           {sectionData?.about_section_title
  //             ? sectionData?.about_section_title
  //             : "About Us"}
  //         </h3>
  //         <p
  //           style={{
  //             fontSize: `${sectionData?.about_clinic_name_px}px`,
  //             color: "black",
  //             fontFamily: `${font_Family}`,
  //           }}
  //           dangerouslySetInnerHTML={{
  //             __html: sectionData?.about_clinic_name,
  //           }}
  //         />
  //         <div className={!isReadMore && styles["description-full"]}>
  //           <p
  //             style={{ 
  //               fontSize: `${sectionData?.about_desc_px}px`,
  //               // maxHeight: descriptionHeight[sectionData?.about_desc_px],
  //             }}
  //             className={`${styles.description} ${
  //               !isReadMore && styles["wrapper"]
  //             }`}
  //             ref={descriptionRef}
  //             dangerouslySetInnerHTML={{
  //               __html: sectionData?.about_desc,
  //             }}
  //           />
  //         </div>
  //         {showReadMore && (
  //           <span
  //             className={styles.readMore}
  //             onClick={() => setIsReadMore((current) => !current)}
  //           >
  //             {isReadMore ? "Show Less" : "Show More"}
  //           </span>
  //         )}
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    <>
      <div className={styles.AboutProfile} data-testid="aboutProfile">
        <section id="About Us" className="AboutProfile pt-0">
          <div className="container-fluid mt-sm-0 my-lg-4  ">
            <div className="row">
              <div className="col-xl-5 col-lg-6 mt-5-lg">
                <div
                  className="profile_image"
                  style={{
                    backgroundImage: `url(${sectionData?.about_image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100%",
                  }}
                ></div>
              </div>
              <div className="col-xl-7 col-lg-6  d-flex flex-column align-items-stretch">
                <h3
                  style={{
                    color: `${theme_color[0]?.home_theme_color}`,
                    fontWeight: 700,
                    fontFamily: `${font_Family}`,
                  }}
                >
                  {sectionData?.about_section_title
                    ? sectionData?.about_section_title
                    : "About Us"}
                </h3>
                <p
                  style={{
                    fontSize: `${sectionData?.about_clinic_name_px}px`,
                    color: "black",
                    fontFamily: `${font_Family}`,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: sectionData?.about_clinic_name,
                  }}
                />
                <p
                  style={{
                    fontSize: `${sectionData?.about_desc_px}px`,
                    maxHeight: descriptionHeight[sectionData?.about_desc_px],
                   
                  }}
                  className={styles.description}
                  ref={descriptionRef}
                  dangerouslySetInnerHTML={{
                    __html: sectionData?.about_desc,
                  }}
                />
                {showReadMore && (
                  <div
                    className={styles.readMore}
                    onClick={(e) => openDoctorData(sectionData?.about_desc)}
                  >
                    Read More
                  </div>
                )}
                {/* <p style={{cursor:'pointer'}} onClick={(e) => openDoctorData(sectionData?.about_desc)}>Read More</p> */}
              </div>
            </div>
            <div>
              <div>
                {/* <p className="px-lg-5" style={{color:'#000',fontFamily:`${font_Family}`}} dangerouslySetInnerHTML={{ __html: sectionData?.about_desc }} /> */}
              </div>
            </div>
          </div>
        </section>
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          classNames={{
            modal: styles.aboutModal,
            // overlayAnimationIn: 'customEnterOverlayAnimation',
            // overlayAnimationOut: 'customLeaveOverlayAnimation',
            // modalAnimationIn: 'customEnterModalAnimation',
            // modalAnimationOut: 'customLeaveModalAnimation',
          }}
          showCloseIcon={false}
          animationDuration={500}
        >
          <div>
            <div className={styles.aboutModalHeader}>
              <div className={styles.aboutModalTitle}>
                {sectionData?.about_section_title
                  ? sectionData?.about_section_title
                  : "About Us"}{" "}
                <div className={styles.titleUnderLine} />
              </div>
              <span
                style={{ height: "100%", float: "right", cursor: "pointer" }}
              >
                <AiOutlineCloseCircle
                  onClick={() => onCloseModal()}
                  style={{ color: "gray" }}
                />
              </span>
            </div>
            <div className={styles.aboutModalContent}>
              <p
                style={{
                  fontSize: `${sectionData?.about_desc_px}px`,
                  color: "#000",
                  fontFamily: `${font_Family}`,
                  textAlign: "justify",
                  padding: "20px",
                }}
                dangerouslySetInnerHTML={{
                  __html: sectionData?.about_desc,
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AboutProfile;
