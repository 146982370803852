import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import "react-responsive-modal/styles.css";
import styles from "./articlesComponent.module.css";
import Article from "./Article";
import { useInterfaceContext } from "../../hooks/InterfaceDetails";
import { useHistory } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import interfaceData from "../../data.json";

const Articles = ({ sectionData }) => {
  const baseUrl = process.env.REACT_APP_ARTICLE_URL;
  const headers = {
    "App-Origin": interfaceData.data.interface_id,
    "Content-Type": "application/json",
  };

  const interfaceDetails = useInterfaceContext();

  const articleContainer = useRef(null);

  const [home] = interfaceDetails.data.filter(
    (section) => section.section_id === "home"
  );

  const history = useHistory();

  // const inputDivStyle = {
  //   border: "1px solid",
  //   borderColor: home?.home_theme_color,
  //   color: home?.home_theme_color,
  //   fontSize: "16px",
  //   display: "flex",
  //   fontWeight: "600",
  //   alignItems: "center",
  //   cursor: "pointer",
  // };

  const defaultNoOfArticles = 9;
  const initialRender = useRef(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [pauseOtherVideos, setPauseOtherVideos] = useState(false);

  const [article, setArticle] = useState();

  useEffect(() => {
    if (currentPage === 1) {
      setArticle(
        interfaceDetails?.getArticles?.data?.slice(0, defaultNoOfArticles)
      );
    } else getPatientArticle();
  }, [currentPage]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (articleContainer.current) {
      scrollToTargetAdjusted();
    }
  }, [currentPage]);

  const getPatientArticle = async () => {
    setLoading(true);
    const res = await fetch(
      baseUrl +
        `contentlibrary/get-patient-article?per_page=${defaultNoOfArticles}&page=${currentPage}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const data = await res.json();
    if (data.response != null) {
      console.log(data.response.getArticle);
      setArticle(data?.response?.getArticle?.data);
    }
    setLoading(false);
  };

  const openFullViewArticle = (data) => {
    // history.push(`/article/${data.id}`);
    window.open(`/article/${data.id}`, "_blank");
  };

  // const currentArticleData = useMemo(async () => {
  //   // const firstPageIndex = (currentPage - 1) * defaultNoOfArticles;
  //   // const lastPageIndex = firstPageIndex + defaultNoOfArticles;
  //   // return interfaceDetails?.getArticles?.data?.slice(
  //   //   firstPageIndex,
  //   //   lastPageIndex
  //   // );

  //   if (currentPage === 1) {
  //     console.log(interfaceDetails?.getArticles?.data);
  //     return interfaceDetails?.getArticles?.data?.slice(0, defaultNoOfArticles);
  //   } else await getPatientArticle();
  // }, [currentPage]);

  const scrollToTargetAdjusted = () => {
    let headerOffset = 170;
    let elementPosition = articleContainer.current.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <>
      {interfaceDetails?.getArticles?.total > 0 && (
        <div className="section-title services">
          <h2
            style={{
              color: home?.home_theme_color,
              fontWeight: 700,
              fontFamily: home?.home_font_family,
            }}
          >
            {sectionData.article_section_title === ""
              ? "Articles"
              : sectionData.article_section_title}
          </h2>
        </div>
      )}
      <div className="container" ref={articleContainer}>
        <div className="container-fluid">
          <div className="row">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <div class={styles.loader}></div>
              </div>
            ) : (
              article?.map((resp, key) => (
                <Article
                  openFullViewArticle={openFullViewArticle}
                  resp={resp}
                  pauseOtherVideos={pauseOtherVideos}
                  setPauseOtherVideos={setPauseOtherVideos}
                  key={key}
                />
              ))
            )}
          </div>

          <div className={styles["pagination-container"]}>
            <Pagination
              itemsPerPage={defaultNoOfArticles}
              totalItems={interfaceDetails?.getArticles?.total}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Articles;
