import { useContext, useState, createContext, useEffect } from "react";
import interfaceData from "../data.json";
import { hexToRgb } from "../Utils/Utils";

const InterfaceContext = createContext();

export const useInterfaceContext = () => {
  return useContext(InterfaceContext);
};

const InterfaceDetails = ({ children }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    "App-Origin": interfaceData.data.interface_id,
    "Content-Type": "application/json",
  };

  const [interfaceDetails, setInterfaceDetails] = useState(null);

  useEffect(() => {
    getInterfaceDetails();
  }, []);

  // useEffect(() => {
  //   // document.title = "About Page chandu";
  // }, []);
  const getInterfaceDetails = async () => {
    // const interfaceId = sessionStorage.getItem("interface_id");
    const interfaceId = interfaceData.data.interface_id;

    const res = await fetch(
      baseUrl +
        `patient-website/get-interface-details?interface_id=${encodeURIComponent(
          interfaceId
        )}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const data = await res.json();

    if (data.response != null) {
      setInterfaceDetails({
        ...data.response,
      });
      const fav = document.getElementById("favicon");
      fav.href = data.response.data[0].home_logo_url;
      const root = document.documentElement;
      root.style.setProperty("--theme", data.response.data[0].home_theme_color);
      root.style.setProperty(
        "--themeRgb",
        hexToRgb(data.response.data[0].home_theme_color)
      );
      sessionStorage.setItem(
        "theme_color",
        data.response.data[0].home_theme_color
      );

      data?.response?.data.forEach((section) => {
        if (section.section_id === "about") {
          document.title = getText(section.about_clinic_name);
          sessionStorage.setItem(
            "doctor_title",
            getText(section.about_clinic_name)
          );
        }
      });

      if (data.response.data[0].home_font_family === "Ariel")
        root.style.setProperty("--font", `Arial`);
      else
        root.style.setProperty(
          "--font",
          data.response.data[0].home_font_family
        );
    }
  };

  const getText = (htmlContent) => {
    const d = document.createElement("div");
    d.innerHTML = htmlContent;
    let textContent = d.textContent || d.innerText;

    return textContent;
  };

  return (
    <InterfaceContext.Provider value={interfaceDetails}>
      {children}
    </InterfaceContext.Provider>
  );
};

export default InterfaceDetails;
