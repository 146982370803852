import React from "react";
import { useInterfaceContext } from "../../hooks/InterfaceDetails";
import { groupByN } from "../../Utils/Utils";
import styles from "./Stats.module.css";

const Stats = ({ sectionData }) => {
  const interfaceDetails = useInterfaceContext();

  const [home] = interfaceDetails.data.filter(
    (section) => section.section_id === "home"
  );

  return (
    <div style={{ margin: "1em 0" }}>
      {sectionData.is_show_title === 1 && (
        <div className="section-title services">
          <h2
            style={{
              color: home?.home_theme_color,
              fontWeight: 700,
              fontFamily: home?.home_font_family,
            }}
          >
            {sectionData.title === "" ? "My Stats" : sectionData.title}
          </h2>
        </div>
      )}
      {groupByN(4, sectionData?.info).map((section, key) => (
        <div
          className={styles.container}
          style={{ backgroundColor: sectionData.bg_color }}
          key={key}
        >
          {section.map((stat, key) => (
            <div className={styles.stat} key={key}>
              <img
                className={styles.icon}
                style={{ backgroundColor: sectionData.icon_circle_color }}
                src={stat.icon}
              ></img>
              <div className={styles.statContent}>
                <div
                  style={{
                    color: sectionData.title_color,
                    fontSize: sectionData.title_px,
                  }}
                >
                  {stat.title}
                </div>
                <div
                  style={{
                    color: sectionData.sub_title_color,
                    fontSize: sectionData.sub_title_px,
                    height: "7rem",
                    overflow: "hidden",
                    overflowY: "auto",
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  {stat.sub_title}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Stats;
