import { useEffect, useRef, useState } from "react";


import { Modal } from "react-responsive-modal";
import styles from "./Testimonial.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-scroll";




 const Testimonial = ({item, themeColor, props,sectionData}) => {

    const testimonialDescRef = useRef();
    const [showReadMore, setShowReadMore] = useState(false);
    //const [font_Family, setFont_Family] = useState(font);

   


    const [open, setOpen] = useState(false);
    const [testimonialData, setTestimonialData] = useState();
    const onCloseModal = () => {
      setOpen(false);
    };
    const openTestimonialData = (data) => {
        setTestimonialData(data);
        setOpen(true);
      };


    useEffect(() => {
        setShowReadMore(isOverflown());
      }, []);


      const isOverflown = () => {
        if (testimonialDescRef.current)
          return (
            testimonialDescRef.current.scrollHeight >
            testimonialDescRef.current.clientHeight + 15
          );
        else return false;
      };


      const inputDivStyle = {
        border: "1px solid",
        borderColor: themeColor,
        color: themeColor,
        fontSize: "13px",
        display: "flex",
        fontWeight: "600",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: "white",
      };

    return (  <div className=" d-flex justify-content-center">
      <div className="testimonials1" >
        <div className="testimonials" >
          <div>
            <div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <h1 className="testimonial-name">{item?.full_name}</h1>
                  <p ref={testimonialDescRef} style={{whiteSpace:'pre-wrap', height : "8rem", position : 'relative', overflow : 'hidden'}}>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    {item?.testimonial}

                    {showReadMore && <span style={{position : 'absolute' , bottom : 0 , right : '15px' , background : 'white'}}></span>}
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  {showReadMore && (
                  <div
                    className={styles.readMore}
                    onClick={(e) => openTestimonialData(sectionData?.testimonialDescRef)}
                  >
                    Read More
                  </div>
                )}

        <Modal
          open={open}
          onClose={onCloseModal}
          center
          classNames={{
            modal: styles.testimonialModal,
          
          }}
          showCloseIcon={false}
          animationDuration={500}
        >
            <div>
            <div className={styles.testimonialModalHeader}>
              <div className={styles.testimonialModalTitle}>
                {sectionData?.testimonial_section_title
                  ? sectionData?.testimonial_section_title
                  : "Testimonial"}{" "}
                <div className={styles.titleUnderLine} />
              </div>
              <span
                style={{ height: "100%", float: "right", cursor: "pointer" }}
              >
                <AiOutlineCloseCircle
                  onClick={() => onCloseModal()}
                  style={{ color: "gray" }}
                />
              </span>
            </div>
            <div className={styles.testimonialModalContent}>
              <p
                style={{
                  fontSize: `${sectionData?.testimonialDescRef_px}px`,
                  
                  color: "#000",
                  textAlign: "justify",
                  padding:"30px",
                  //fontFamily: `${font_Family}`,
 }}
                dangerouslySetInnerHTML={{
                  __html: item?.testimonial,
                }}
              />
            </div>
          </div>

        </Modal>
                  </div>
                
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>  )


    
}





export default Testimonial;