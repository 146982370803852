import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./WorkingHours.module.css";
import bell from "../../assets/ic_holiday.png";
import clock from "../../assets/ic_clock.png";
import { useInterfaceContext } from "../../hooks/InterfaceDetails";
import getWindowDimensions from "../../hooks/getWindowDimensions";
import "./quill.css";

const WorkingHours = ({ sectionData }) => {
  const interfaceDetails = useInterfaceContext();

  const [home] = interfaceDetails.data.filter(
    (section) => section.section_id === "home"
  );

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div style={{ margin: "1em 0" }}>
      {sectionData.is_show_title === 1 && (
        <div className="section-title services">
          <h2
            style={{
              color: home?.home_theme_color,
              fontWeight: 700,
              fontFamily: home?.home_font_family,
            }}
          >
            {sectionData.title === "" ? "Working Hours" : sectionData.title}
          </h2>
        </div>
      )}
      <div
        className={styles.container}
        style={{
          backgroundColor: sectionData.bg_color,
          gridTemplateColumns:
            windowDimensions.width < 768
              ? "repeat(auto-fit , 400px)"
              : sectionData.template_id === 3 || sectionData.template_id === 4
              ? "500px"
              : "repeat(auto-fit , 500px)",
          gap:
            sectionData.template_id === 3 || sectionData.template_id === 4
              ? "0.5em"
              : "4em",
          color: sectionData.text_color,
          fontSize: sectionData.text_px,
        }}
      >
        <div className={styles.hours}>
          <img className={styles.icon} src={clock}></img>
          <div
            style={{ textAlign: "center" }}
            className="ql-editor"
            dangerouslySetInnerHTML={{ __html: sectionData.left_side_text }}
          ></div>
        </div>
        {sectionData.right_side_text != "" && (
          <div className={styles.hours}>
            <img className={styles.holidayIcon} src={bell}></img>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{ __html: sectionData.right_side_text }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkingHours;
