import React, { createContext, useContext, useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ContactUs.module.css';
import interfaceData from "../../data.json";
import { makeStyles } from '@mui/styles';
import success from "../../assets/success.png";
import { useForm } from "react-hook-form";
import getWindowDimensions from "../../hooks/getWindowDimensions";
import InterfaceDetails, { useInterfaceContext } from "../../hooks/InterfaceDetails";
import { ToastProvider, useToasts } from 'react-toast-notifications';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import "react-responsive-modal/styles.css";
import { color } from '@mui/system';
// import interfaceData from "../data.json";
import Button from '@mui/material/Button';
import { getInterfaceID, headers } from '../../Utils/Utils';


const ContactUs = (sectionData, ondisplayName) => {
  const [open, setOpen] = React.useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const interfaceId = interfaceData.data.interface_id;
  const { addToast } = useToasts();
  const headers = {
    "App-Origin": interfaceData.data.interface_id,
    "Content-Type": "application/json"
  };
  // const { handleSubmit,  register, formState: { errors }, } = useForm();
  const { register, reset, formState: { errors }, handleSubmit } = useForm();
  const interfaceDetails = useInterfaceContext();
  const [home] = interfaceDetails?.data?.filter(
    (section) => section.section_id === "home"
  );
   const [sucess, setSucess] = useState(false);
 
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (formData) => {
    console.log("headers>>>>>>>>"+JSON.stringify(headers));
   // let messageElement = document.getElementById('messageId')

    const payload = {
      interface_id: parseInt(interfaceId),
      full_name: formData.fname,
      contact_number: formData.phone,
      contact_email: formData.email,
      message:formData.message
    }
    console.log("payload>>>>>>>>"+JSON.stringify(payload));
    const res = await fetch(baseUrl +"patient-website/save-contact-us-form-data", {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    })
      .catch((error) => {
        console.error(error);
        addToast(error.message, { appearance: 'error' });
        if (navigator.onLine)
          // toast("Whitecoats is currently unavailable. \n Try again later");
          // else toast("You are offline");
          return;
      });
    const data = await res.json();
    if (data.response === 1) {
      //setOpen(true);
      setSucess(true);
      reset();
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setSucess(false)
      }, 2000)
    }
  };

  useEffect(()=>{
    console.log(sectionData)
  },[])

  

  return (
    <>
      <div className="contact">
      {/* {sectionData?.contact_us_section_show_title} */}
     <p style={{color:'black'}}>{sectionData?.sectionData?.contact_us_section_show_title == 1 }</p> 
        {sectionData?.sectionData?.contact_us_section_show_title == 1 ? (
          <div className="section-title">
            <h2 style={{ color: home?.home_theme_color, fontFamily: home?.home_font_family }}>{sectionData.sectionData.contact_us_section_title ? sectionData.sectionData.contact_us_section_title : "Contact Us"}</h2>
          </div>
        ):null}
        <section id="appointment" class="appointment">
          <div class="container">
          <div className={styles.contactuspanel_default}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div class="row mt-2" style={{marginLeft:"4px",marginRight:"4px"}}>
                <div class="col-md-6 form-group mt-3">
                <span className={styles.contactus_label}>Your Full Name *</span>
                  <input type="text" name="name" maxLength="30" {...register("fname", {
                    required: true,
                    maxLength: 30,
                    minLength: 1,
                    pattern: /^[a-zA-Z0-9 ]*$/,
                  })} class="form-control" id="fName11" placeholder="Your Full Name" style={{ lineHeight: '2' }} />
                 

               <span className={styles.contactus_label}>Mobile Number *</span>
                  <input onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} type="tel"  maxLength="10"  {...register("phone", {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                  })} class="form-control" name="phone" id="phone" placeholder="Your Contact Number" style={{ lineHeight: '2', background: 'white' }} />
                                  
                  <span className={styles.contactus_label}>Email ID</span>
                  <input type="text"  {...register("email", {
                    required: false,
                    maxLength: 150,
                    pattern:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,

                   
                  })} class="form-control" name="email" id="email"  placeholder="Your Email" style={{ lineHeight: '2' }} />
                 
                </div>
               
              <div class="col-md-6 form-group mt-3 ">
                <span className={styles.contactus_label}>Message *</span>
                  <textarea class="form-control" maxLength="1000" id="message"  {...register("message",
                {
                  required: true,
                  minLength: 1,
                  maxLength: 1000,
                })}  name="message" rows="10" cols="8" placeholder="Your Message"></textarea>
                </div>
              </div>
              <div class="dispaly-center">
                <input type="submit"  style={{backgroundColor:home?.home_theme_color}} />
              </div>
              <div className="dispaly-center" >
              {(errors.fname || errors.phone || errors.email || errors.message) &&  <span className="text-danger" id="messageId"  style={{paddingBottom:"10px",fontSize:"12px"}}>
                         All(*) fields are Mandatory
                </span>
              }

             { sucess &&  <span  id="messageId"  style={{paddingBottom:"10px",fontSize:"14px"}}>
                        <b>Thanks for submitting the call back request </b>
                </span>
              }
             
                </div>
            </form>
          </div>
          </div>
        </section>
      </div>
      <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='dispaly-center'><img src={success}/></div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="" id="alert-dialog-description">
          <h3 style={{fontWeight: '600', fontSize: '25px'}}>Good Job! Thanks for submitting the form!</h3> 
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions> */}
        <div className='dispaly-center mb-3'>
           <Button style={{background:'#4d01ff',color:'#fff'}} onClick={handleClose}>ok</Button>
           </div>
         
        {/* </DialogActions> */}
      </Dialog>
    </div>
    </>
  );
};


export default ContactUs;
