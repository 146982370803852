import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ProfileBanner.module.css";
import { useInterfaceContext } from "../../../../hooks/InterfaceDetails";
import { BsArrowLeftCircle } from "react-icons/bs";
import { BsArrowRightCircle } from "react-icons/bs";

import { themeColor, logo, font } from "../../../../Utils/Utils";
import getWindowDimensions from "../../../../hooks/getWindowDimensions";

const ProfileBanner = (props) => {
  const interfaceDetails = useInterfaceContext();
  const [doctorsData, setDoctorsData] = useState();
  console.log("Banner Component" + JSON.stringify(props));
  const [font_Family, setFont_Family] = useState(font);
  const banner_data = interfaceDetails.data[0];

  const [images, setImages] = useState([]);

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  const nextButton = useRef();

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    getBannerImages();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   loopCarousel();
  // }, [images]);

  // const loopCarousel = () => {
  //   if (nextButton.current) {
  //     console.log(nextButton.current);
  //     setTimeout(() => {
  //       if (nextButton.current) {
  //         nextButton.current.click();
  //       }
  //       loopCarousel();
  //     }, 5000);
  //   }
  // };

  const getBannerImages = async () => {
    const [homeSection] = interfaceDetails?.data?.filter(
      (section) => section.section_id === "home"
    );

    let bannerImages = [];

    for (let i = 0; i < 5; i++) {
      if (i === 0) {
        if (
          homeSection.home_banner_image &&
          homeSection.home_banner_image_status === 1
        )
          bannerImages.push(homeSection.home_banner_image);
      } else {
        if (
          homeSection[`home_banner_image${i + 1}`] &&
          homeSection[`home_banner_image${i + 1}_status`] === 1
        )
          bannerImages.push(homeSection[`home_banner_image${i + 1}`]);
      }
    }
    setImages(bannerImages);
  };

  return (
    // <div className="container d-none d-sm-block">
    //   <div className="row">
    //     <div className="col-6">
    //       <div>
    //         {/* {props?.doctors?.websiteConfigDetails?.welcome_text !== "" && */}
    //         <h3 >
    //           <p
    //             dangerouslySetInnerHTML={{
    //               __html: banner_data?.home_banner_heading,
    //             }}
    //             style={{
    //               fontSize: `${banner_data?.home_banner_heading_px}px`,
    //               textAlign: "left",fontFamily:`${font_Family}`
    //             }}
    //           ></p>
    //           <p className='mt-lg-2'
    //             dangerouslySetInnerHTML={{
    //               __html: banner_data?.home_banner_sub_heading,
    //             }}
    //             style={{
    //               fontSize: `${banner_data?.home_banner_sub_heading_px}px `,
    //               textAlign: "left",fontFamily:`${font_Family}`
    //             }}
    //           ></p>
    //         </h3>

    //         {/* } */}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <div
        id="bannerCarousel"
        className="carousel carousel-dark slide"
        data-bs-ride="carousel"
        data-wrap="true"
        data-bs-interval="2000"
        style={{ marginTop: "75px", marginBottom: "1.5em" }}
      >
        <div className="carousel-indicators">
          {images.length !== 1 &&
            images.map((image, index) => (
              <button
                type="button"
                data-bs-target="#bannerCarousel"
                data-bs-slide-to={index}
                className={`carousel-indicator ${index === 0 ? "active" : ""}`}
                aria-label={`Slide ${index}`}
                {...{ "aria-current": index === 0 ? "true" : undefined }}
              ></button>
            ))}
        </div>
        <div className="carousel-inner">
          {images.map((image, index) => (
            <div
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              // data-bs-interval="2000"
            >
              <img
                src={image}
                style={{
                  height: windowDimensions.width > 768 ? "70vh" : "25vh",
                  width: "100%",
                }}
                className="d-block w-100"
                alt="..."
              />
            </div>
          ))}
        </div>
        {images.length !== 1 ? (
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#bannerCarousel"
            data-bs-slide="prev"
          >
            <BsArrowLeftCircle size={30} style={{ color: "black" }} />
            <span className="visually-hidden">Previous</span>
          </button>
        ) : null}
        {images.length !== 1 ? (
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#bannerCarousel"
            data-bs-slide="next"
            ref={nextButton}
          >
            <BsArrowRightCircle size={30} style={{ color: "black" }} />
            <span className="visually-hidden">Next</span>
          </button>
        ) : null}

        {windowDimensions.width > 768 && (
          <h3
            style={{
              position: "absolute",
              top: "50%",
              left: "12%",
              translate: "0 -50%",
              width: "50%",
            }}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: banner_data?.home_banner_heading,
              }}
              style={{
                fontSize: `${banner_data?.home_banner_heading_px}px`,
                textAlign: "left",
                fontFamily: `${font_Family}`,
              }}
            ></p>
            <p
              className="mt-lg-2"
              dangerouslySetInnerHTML={{
                __html: banner_data?.home_banner_sub_heading,
              }}
              style={{
                fontSize: `${banner_data?.home_banner_sub_heading_px}px `,
                textAlign: "left",
                fontFamily: `${font_Family}`,
              }}
            ></p>
          </h3>
        )}
      </div>

      {/* <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={images[0]} height="300" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={images[0]} height="300" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={images[0]} height="300" class="d-block w-100" alt="..." />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div> */}
    </>
  );
};

export default ProfileBanner;
