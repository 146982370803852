import { useEffect, useState } from "react";
import styles from "./pagination.module.css";
import { usePagination, DOTS } from "./usePagination";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import getWindowDimensions from "../../hooks/getWindowDimensions";

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  const paginationRange = usePagination({
    currentPage: currentPage,
    totalCount: totalItems,
    siblingCount: windowDimensions.width > 728 ? 1 : 0,
    pageSize: itemsPerPage,
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNextPage = () => {
    setCurrentPage((prev) => (prev >= lastPage ? prev : currentPage + 1));
  };
  const onPreviousPage = () => {
    setCurrentPage((prev) => (prev <= 1 ? prev : prev - 1));
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <div
        onClick={onPreviousPage}
        className={`${styles["navigation-icons"]} ${
          currentPage === 1 && styles.disabled
        }`}
      >
        <MdNavigateBefore />
      </div>

      {paginationRange.map((pageNumber, key) => {
        if (pageNumber === DOTS) {
          return (
            <span
              className={`${styles["navigation-pills"]} ${styles["navigation-icons"]}`}
              key={key}
            >
              &#8230;
            </span>
          );
        }

        return (
          <span
            className={`${styles["navigation-pills"]} ${
              styles["navigation-icons"]
            } ${currentPage === pageNumber && styles.selected}`}
            // className={classnames("pagination-item", {
            //   selected: pageNumber === currentPage,
            // })}
            onClick={() => setCurrentPage(pageNumber)}
            key={key}
          >
            {pageNumber}
          </span>
        );
      })}

      <div
        onClick={onNextPage}
        className={`${styles["navigation-icons"]} ${
          currentPage === lastPage && styles.disabled
        }`}
      >
        <MdNavigateNext />
      </div>
    </div>
  );
};

export default Pagination;
