import React from 'react';
import { useInterfaceContext } from '../../hooks/InterfaceDetails';
import Images from './Images';
import Videos from './Videos';

const Gallery = ({sectionData, ondisplayNameHandler, setMenu}) => {
    const interfaceDetails = useInterfaceContext();
 
    
    const [home] = interfaceDetails?.data?.filter(
      (section) => section.section_id === "home"
    );


    return (
        <div> 
            <div className="container">
        {sectionData?.gallery_section_title !== null && sectionData?.gallery_section_show_title !== 0 && (<div className="section-title">
          <h2 style={{ color: home?.home_theme_color , fontFamily: home?.home_font_family}} >{sectionData?.gallery_section_title ? sectionData?.gallery_section_title : "Our Gallery"}</h2>
          <p>{sectionData?.gallery_section_description}</p>
        </div>)}
        <Images
          sectionData={sectionData}
          ondisplayName={ondisplayNameHandler}
          href={setMenu}
        />
        <Videos
          sectionData={sectionData}
          ondisplayName={ondisplayNameHandler}
          href={setMenu}
        />
      </div>
            
        </div>
    );
};

export default Gallery;