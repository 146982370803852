import React, { useState } from 'react';
import { Vortex } from 'react-loader-spinner'
const Spinner = (props) => {
    const [loader,setLoader]= useState(true);
    return (
        <div>
            <Vortex
                visible={loader}
                height="80"
                width="80"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
            />
        </div>
    );
};

export default Spinner;