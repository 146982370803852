import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { IoMdDownload } from "react-icons/io";
import styles from "./displayPdf.module.css";
import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiFillPrinter,
  AiOutlineMenu,
} from "react-icons/ai";

import printJS from "print-js";
// import { pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const scaleValues = [25, 33, 50, 67, 75, 80, 90, 100];

const DisplayPdf = ({ url, width, height }) => {

  const [title, setTitle] = useState("");
  const [numPages, setNumPages] = useState(1);
  const [scale, setScale] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageWidth, setPageWidth] = useState(width);
  const sideBarContainer = useRef(null);
  const pdfContainer = useRef(null);

  const documentRef = useRef(null);
  const documentInputRef = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      getCurrentPage();
    };
    documentInputRef?.current?.addEventListener("scroll", onScroll);

    return () => {
      documentInputRef?.current?.removeEventListener("scroll", onScroll);
    };
  }, []);

  const getCurrentPage = () => {
    const pages = documentRef.current.pages;
    const yPosition = documentInputRef?.current?.scrollTop;
    const documentOffset = documentInputRef?.current?.offsetTop;

    for (let i = pages.length - 1; i >= 0; i--) {
      const page = pages[i];

      if (yPosition >= page.offsetTop - documentOffset)
        return setCurrentPage(page.getAttribute("data-page-number"));
    }
  };

  const onDocumentLoadSuccess = async (pdf) => {
    setNumPages(pdf.numPages);
    const data = await pdf.getMetadata();
    setTitle(
      data.info.Title || url.split("/").pop().split("#")[0].split("?")[0]
    );
  };

  const decreaseScale = () => {
    if (scale === 25) return;
    setScale((current) => scaleValues[scaleValues.indexOf(current) - 1]);
  };

  const increaseScale = () => {
    if (scale === 100) return;
    setScale((current) => scaleValues[scaleValues.indexOf(current) + 1]);
  };

  const scrollToPage = (pageNo) => {
    if (!pageNo || isNaN(pageNo)) return;
    if (pageNo > numPages || pageNo < 1) {
      getCurrentPage();
      return;
    }
    const pages = documentRef.current.pages;
    pages[pageNo - 1].scrollIntoView();
  };

  const printPdf = async () => {
    const data = await fetch(url);
    const blob = await data.blob();
    let link = URL.createObjectURL(blob);
    printJS(link);
  };

  const downloadPdf = async () => {
    const data = await fetch(url);
    const blob = await data.blob();
    let link = URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.setAttribute(
      "download",
      url.split("/").pop().split("#")[0].split("?")[0]
    );
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const toggleNavigator = () => {
    if (sideBarContainer.current.style.display === "none") {
      sideBarContainer.current.style.display = "block";
      setScale(75);
    } else {
      sideBarContainer.current.style.display = "none";
      setScale(100);
    }
  };

  return (
    <div className={styles["pdf-container"]}>
      <div className={styles.toolbar}>
        <div style={{ display: "flex", width: "35%" }}>
          <span style={{ marginRight: "1em", cursor: "pointer" }}>
            <AiOutlineMenu size={23} onClick={toggleNavigator} />
          </span>
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              margin: "auto",
            }}
          >
            {title}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>
            <input
              type="number"
              className={styles.pageInput}
              min={0}
              max={numPages}
              // defaultValue={1}
              value={currentPage}
              onBlur={(e) => {
                if (e.target.value == "") getCurrentPage();
              }}
              onChange={(e) => {
                setCurrentPage(e.target.value);
                scrollToPage(e.target.value);
              }}
            />
            / &nbsp; &nbsp; {numPages}
          </span>
          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              borderRight: "1.5px solid white",
              height: "1.3em",
            }}
          ></div>
          <AiOutlineMinus
            style={{ cursor: "pointer" }}
            onClick={decreaseScale}
          />
          <span style={{ width: "43px", textAlign: "center" }}>
            &nbsp; {scale} &nbsp;
          </span>
          <AiOutlinePlus
            style={{ cursor: "pointer" }}
            onClick={increaseScale}
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "35%" }}
        >
          <IoMdDownload
            style={{ marginRight: "1em", cursor: "pointer" }}
            onClick={() => downloadPdf()}
          />
          <AiFillPrinter style={{ cursor: "pointer" }} onClick={printPdf} />
        </div>
      </div>

      <div ref={pdfContainer} className={styles["mci-pdf-preview"]}>
        <div
          ref={sideBarContainer}
          style={{
            width: "35%",
            color: "white",
            display: "none",
          }}
          className={styles["sidebar-animation"]}
        >
          <Document
            ref={documentRef}
            inputRef={documentInputRef}
            file={url}
            className={` ${styles["pdf-document"]} ${styles["navigator-container"]}`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <div>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={index}
                  canvasBackground={"white"}
                  width={100}
                  height={100}
                  pageNumber={index + 1}
                  // renderMode={"svg"}
                  className={`${styles["pdf-page"]} ${styles["pointer"]}`}
                  renderAnnotationLayer={false}
                  onClick={() => {
                    setCurrentPage(index + 1);
                    scrollToPage(index + 1);
                  }}
                />
              ))}
            </div>
          </Document>
        </div>
        <Document
          ref={documentRef}
          inputRef={documentInputRef}
          file={url}
          className={styles["pdf-document"]}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <div className={styles["pdf-page-container"]}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                id={`page_${index + 1}`}
                key={`page_${index + 1}`}
                canvasBackground={"white"}
                width={pageWidth}
                height={height}
                pageNumber={index + 1}
                scale={scale / 100}
                // renderMode={"svg"}
                className={styles["pdf-page"]}
                renderAnnotationLayer={false}
              />
            ))}
          </div>
        </Document>
      </div>
    </div>
  );
};

export default DisplayPdf;
