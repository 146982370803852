import React, { useState, useEffect, useRef } from "react";
import data from "../../src/data.json";
import { useHistory } from "react-router-dom";
import { Button, Link } from "react-scroll";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { themeColor, logo, font } from "../Utils/Utils";
import { useInterfaceContext } from "../hooks/InterfaceDetails";
import { AiFillCaretDown } from "react-icons/ai";
import * as Scroll from "react-scroll";
import getWindowDimensions from "../hooks/getWindowDimensions";
import youtubeIcon from "../assets/youtube_icon.png";

let scroll = Scroll.animateScroll;

function useOutsideAlerter(ref, setMoreMenu) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setMoreMenu(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Header = ({ doctorData, ondisplayName }) => {
  const interfaceDetails = useInterfaceContext();

  const [moreMenu, setMoreMenu] = useState(false);
  const topBar = useRef(null);
  const haederScroll = useRef(null);
  const containerScroll = useRef(null);
  const moreMenuRef = useRef(null);
  const moreMenuButtonRef = useRef(null);
  const moreMenuButtonIconRef = useRef(null);

  const [scrollOffset, setScrollOffset] = useState(-130);

  useOutsideAlerter(moreMenuRef, setMoreMenu);

  const [contact, setContact] = useState();
  const [mail, setMail] = useState();
  const [menuList, setMenuList] = useState(interfaceDetails);
  const data = [];
  const array = [];
  const [bannerInfo, setBannerInfo] = useState(themeColor);
  const [logoUrl, setLogoUrl] = useState(logo);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );
  const app_data = interfaceDetails?.data
    ?.filter((index) => index.section_id === "patientapp")
    .map((index) => {
      array.push(index);
      return index;
    });
  //console.log("Appsection>>>>>>>>>" + app_data[0]?.app_separate_section);

  const blog_data = interfaceDetails?.menu
  ?.filter((index) => index.section_id === "blog")
  .map((index) => {
    array.push(index);
    return index;
  });
  //console.log("Blog Link>>>>>>>>>" + blog_data[0]?.link);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    
    if (windowDimensions.width < 768) {
      setScrollOffset(-85);
    }

    const onHeaderScroll = () => {
      if (windowDimensions.width > 768) {
        if (window.scrollY > 0) {
          //  topBar?.current?.classList?.add('header-scrolled')
          if (haederScroll) {
            haederScroll?.current?.classList?.add("header-scrolled");
            topBar?.current?.classList?.add("topbar-scrolled");
            // document.getElementById("bannerCarousel").style.marginTop = '40px';
            setScrollOffset(-85);
          }
        } else {
          haederScroll?.current?.classList?.remove("header-scrolled");
          if (topBar) {
            topBar?.current?.classList?.remove("topbar-scrolled");
          }
          setScrollOffset(-130);
          // document.getElementById("bannerCarousel").style.marginTop = '75px';
        }
      }
    };
    window.addEventListener("scroll", onHeaderScroll);
    return () => window.removeEventListener("scroll", onHeaderScroll);
  }, []);

  useEffect(() => {
    if (interfaceDetails) setMenuList(interfaceDetails);
  }, [interfaceDetails]);

  useEffect(() => {
    getBuildData();

    // getMenuList();
  }, []);
  const social_data = interfaceDetails?.data
    ?.filter((index) => index.section_id === "footer")
    .map((index) => {
      return index;
    });
  const book_appointment = interfaceDetails?.data
    ?.filter((index) => index.section_id === "home")
    .map((index) => {
      return index;
    });

  const getBuildData = async () => {
    const formatData = data;
    sessionStorage.setItem("interface_id", formatData?.data?.interface_id);
  };

  const openLink = (index) => {
    let url = index;
    let openUrl;
    if (index.includes("www.")) {
      openUrl = url.replace("www.", "http://");
    }
    if (index.includes("https://")) {
      openUrl = url.replace("https://", "https://");
    }
    if (index.includes("http://")) {
      openUrl = url.replace("http://", "http://");
    }

    window.open(openUrl);
  };

  const scrollToBottom = () => {
    scroll.scrollToBottom({
      smooth: "linear",
      duration: 0,
      // offset: 100,
    });
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <>
      <div
        id="topbar"
        className="d-flex align-items-center d-lg-flex d-none"
        ref={topBar}
      >
        <div className="container ">
          <div
            className="contact-info "
            style={{
              display: "flex",
              // flexDirection: "column",
              padding: "2px",
              justifyContent: "space-between",
            }}
          >
            <div className="contact-info d-flex align-items-center">
              {social_data[0]?.footer_contact_email != null &&
                social_data[0]?.footer_contact_email != "" && (
                  <>
                    <i className="bi bi-envelope"></i>
                    <a
                      style={{ fontFamily: `${font}` }}
                      href={`mailto:${social_data[0]?.footer_contact_email}`}
                    >
                      {social_data[0]?.footer_contact_email}
                    </a>
                  </>
                )}

              {social_data[0]?.footer_contact_number != null &&
                social_data[0]?.footer_contact_number != "" && (
                  <>
                    <i className="bi bi-phone"></i>
                    <a
                      style={{ fontFamily: `${font}` }}
                      href={`tel:${social_data[0]?.footer_contact_number}`}
                    >
                      {social_data[0]?.footer_contact_number}
                    </a>
                  </>
                )}
            </div>
            <div className="d-none d-lg-flex social-links align-items-center">
              {social_data[0]?.footer_facebook != null &&
                social_data[0]?.footer_facebook != "" && (
                  <button
                    onClick={() => openLink(social_data[0]?.footer_facebook)}
                    type="button"
                    className="btn"
                  >
                    <img
                      src="assets/footer_images/ic_facebook.png"
                      style={{ width: "25px" }}
                    />
                  </button>
                )}
              {social_data[0]?.footer_twitter != null &&
                social_data[0]?.footer_twitter != "" && (
                  <button
                    style={{ borderRadius: "50%" }}
                    onClick={() => openLink(social_data[0]?.footer_twitter)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_twitter.png"
                      style={{ width: "25px" }}
                    />
                  </button>
                )}
              {social_data[0]?.footer_linked_in != null &&
                social_data[0]?.footer_linked_in != "" && (
                  <button
                    style={{ borderRadius: "50%" }}
                    onClick={() => openLink(social_data[0]?.footer_linked_in)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_linkedin.png"
                      style={{ width: "25px" }}
                    />
                  </button>
                )}

              {social_data[0]?.footer_instagram != null &&
                social_data[0]?.footer_instagram != "" && (
                  <button
                    style={{ borderRadius: "50%" }}
                    onClick={() => openLink(social_data[0]?.footer_instagram)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_instagram.png"
                      style={{ width: "25px" }}
                    />
                  </button>
                )}
              {social_data[0]?.footer_gbp != null &&
                social_data[0]?.footer_gbp != "" && (
                  <button
                    style={{ borderRadius: "50%" }}
                    onClick={() => openLink(social_data[0]?.footer_gbp)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_gmb.png"
                      style={{ width: "25px" }}
                    />
                  </button>
                )}
              {social_data[0]?.footer_whatsapp != null &&
                social_data[0]?.footer_whatsapp != "" && (
                  <button
                    style={{ borderRadius: "50%" }}
                    onClick={() => openLink(social_data[0]?.footer_whatsapp)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_whatsapp.png"
                      style={{ width: "25px" }}
                    />
                  </button>
                )}
              {social_data[0]?.footer_youtube != null &&
                social_data[0]?.footer_youtube != "" && (
                  <button
                    style={{ borderRadius: "50%" }}
                    onClick={() => openLink(social_data[0]?.footer_youtube)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_youtube.png"
                      style={{ width: "25px" }}
                    />
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>

      <header id="header" className="fixed-top " ref={haederScroll}>
        <div
          className="container d-flex align-items-center"
          ref={containerScroll}
        >
          <span class="logoSection">
            <Link
              to="home"
              offset={scrollOffset}
              className="logo me-auto"
              style={{ cursor: "pointer" }}
            >
              <img
                src={book_appointment[0]?.home_logo_url}
                alt=""
                className="img-fluid "
                style={{ maxHeight: "50px" }}
              />
            </Link>
          </span>
          <span className=".d-none .d-sm-block .d-md-none">
            <i
              className="bi bi-list mobile-nav-toggle"
              onClick={onOpenModal}
            ></i>
          </span>
          <nav
            id="navbar"
            className="navbar"
            style={{
              marginRight:
                book_appointment[0]?.home_book_appointment === 0 ? "100px" : "",
            }}
          >
            <ul
              style={{
                fontFamily: `${book_appointment[0]?.home_font_family}`,
              }}
            >
              {interfaceDetails?.menu?.slice(0, 5).map((res, key) => (
                <li className="cursor-pointer" key={key}>
                  <Link
                    to={res.section_id}
                    onClick={() => {
                      if (res.section_id === "footer") {scrollToBottom();
                      }else if (res.section_id === "blog") {openInNewTab(blog_data[0]?.link);
                      }
                    }}
                    className="menu-link"
                    spy={true}
                    // smooth={true}
                    // offset={res.section_id === "footer" ? -90 : -120}
                    offset={scrollOffset}
                    onSetActive={() => {
                      if (moreMenuButtonRef.current) {
                        moreMenuButtonRef.current.style.color = "#2c4964";
                        moreMenuButtonRef.current.style.borderColor = "#fff";
                        moreMenuButtonIconRef.current.style.color = "lightgray";
                        moreMenuButtonIconRef.current.style.borderColor =
                          "#fff";
                      }
                    }}
                  >
                    <span
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      {res.name}
                    </span>
                  </Link>
                </li>
              ))}
              {interfaceDetails?.menu?.length > 5 && (
                <>
                  <li className="cursor-pointer">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 2px",
                        borderBottom: "2px solid #fff",
                        color: "#2c4964",
                      }}
                      ref={moreMenuButtonRef}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontFamily: `${book_appointment[0]?.home_font_family}`,
                        }}
                        onClick={() => setMoreMenu((prev) => !prev)}
                      >
                        More{" "}
                        <span
                          style={{ color: "lightgray" }}
                          ref={moreMenuButtonIconRef}
                        >
                          <AiFillCaretDown size={12} />
                        </span>
                      </span>
                    </div>

                    <div
                      className="more-menu"
                      ref={moreMenuRef}
                      style={{ display: moreMenu ? "block" : "none" }}
                    >
                      {interfaceDetails?.menu?.slice(5).map((res, key) => {
                        if (
                          res.section_id === "patientapp" &&
                          app_data[0]?.app_separate_section == 0
                        )
                          return null;
                        return (
                          <Link
                            to={res.section_id}
                            className="menu-link"
                            spy={true}
                            // smooth={true}
                            onSetActive={() => {
                              if (moreMenuButtonRef.current) {
                                moreMenuButtonRef.current.style.color =
                                  book_appointment[0]?.home_theme_color;
                                moreMenuButtonRef.current.style.borderColor =
                                  book_appointment[0]?.home_theme_color;
                                moreMenuButtonIconRef.current.style.color =
                                  book_appointment[0]?.home_theme_color;
                                moreMenuButtonIconRef.current.style.borderColor =
                                  book_appointment[0]?.home_theme_color;
                              }
                            }}
                            offset={scrollOffset}
                            style={{
                              marginBottom: "5px",
                              width: "fit-content",
                            }}
                            onClick={() => {
                              if (res.section_id === "footer") {scrollToBottom();
                              setMoreMenu(false);
                              } else if (res.section_id === "blog") {
                                openInNewTab(blog_data[0]?.link);
                              }
                              
                            }}
                            key={key}
                          >
                            <span
                              style={{
                                pointerEvents: "none",
                              }}
                            >
                              {res.name}
                            </span>
                          </Link>
                        );
                      })}
                    </div>
                  </li>
                </>
              )}
            </ul>
          </nav>
          {book_appointment[0]?.home_book_appointment === 0 ||
            (book_appointment[0]?.home_book_appointment === 1 && (
              <button
                className="appointment-btn scrollto"
                onClick={() =>
                  openLink(book_appointment[0]?.home_book_appt_redirect_url)
                }
                style={{
                  background: book_appointment[0]?.home_theme_color,
                  border: book_appointment[0]?.home_theme_color,
                  fontFamily: `${font}`,
                }}
              >
                <span
                  className="d-none d-md-inline"
                  style={{ cursor: "pointer" }}
                ></span>
                {book_appointment[0]?.home_book_appt_text
                  ? book_appointment[0]?.home_book_appt_text
                  : "Book Appointments"}
              </button>
            ))}

          {/* <i className="bi bi-list mobile-nav-toggle" onClick={onOpenModal}></i> */}
        </div>
      </header>
      <Modal open={open} onClose={onCloseModal} classNames={{ modal: "model" }}>
        <ul
          style={{ color: `${bannerInfo?.theme_color}`, fontFamily: `${font}` }}
        >
          {interfaceDetails?.menu?.map((res, key) => {
            if (
              res.section_id === "patientapp" &&
              app_data[0]?.app_separate_section == 0
            )
              return null;
            return (
              <Link
                className="mobile-menu-link"
                to={res.section_id}
                spy={true}
               // onClick={onCloseModal}
                onClick={() => {
                  if (res.section_id === "blog") {
                    openInNewTab(blog_data[0]?.link);
                    onCloseModal(true);
                  }else{
                    onCloseModal(true);
                  }
                }}
                offset={scrollOffset}
                key={key}
              >
                <span
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  {res.name}
                </span>
              </Link>
            );
          })}
        </ul>
      </Modal>
    </>
  );
};

export default Header;
