import React, { useState, useEffect,useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Specialities.module.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useInterfaceContext } from "../../hooks/InterfaceDetails";

const Specialities = ({ sectionData }) => {
  const interfaceDetails = useInterfaceContext();

  const [expanded, setExpanded] = useState([]);

  const [home] = interfaceDetails.data.filter(
    (section) => section.section_id === "home"
  );

  return (
    <>
      <div className="section-title services">
        <h2
          style={{
            color: home?.home_theme_color,
            // fontSize: "32px",
            fontWeight: 700,
            fontFamily: home?.home_font_family,
          }}
        >
          {sectionData.service_section_title === ""
            ? "Our Services"
            : sectionData.service_section_title}
        </h2>
        {/* )} */}
      </div>{" "}
      {sectionData?.services?.length > 0 && (
        <div style={{ margin: "30px 0px" }}>
          <div className="d-flex justify-content-center">
            <div className="service-card row" style={{ height: "fit-content" }}>
              {sectionData?.services?.map((res, key) => {
                if (
                  (!res?.desc || res?.desc === "") &&
                  res?.sub_categories.length === 0
                )
                  return (
                    <div className="col-lg-4 col-md-6 col-sm-12 p-0" key={key}>
                      <div className={styles.serviceContainer}>
                        <div className={styles.serviceAccordian}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1em",
                              width: "100%",
                              padding: "10px ",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minWidth: "50px",
                                minHeight: "35px",
                              }}
                            >
                              {res.icon !== null ? (
                                <img
                                  src={res.icon}
                                  style={{ height: "35px", width: "35px" }}
                                  alt="service icon"
                                />
                              ) : (
                                <p className={styles.circledropdown} />
                              )}
                            </div>
                            <div
                              style={{
                                fontSize: `${
                                  res?.title_px ? res?.title_px : 15
                                }px`,
                                marginBottom: "3px",
                                marginTop: "10px",
                              }}
                              dangerouslySetInnerHTML={{ __html: res?.title }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                else
                  return (
                    <div className="col-lg-4 col-md-6 col-sm-12 p-0" key={key}>
                      <Accordion
                        onChange={(e, expanded) => {
                          if (expanded)
                            setExpanded((prev) => [...prev, res.id]);
                          else
                            setExpanded((prev) =>
                              prev.filter((id) => id != res.id)
                            );
                        }}
                        className={
                          expanded.includes(res.id)
                            ? styles.serviceAccordianExpanded
                            : styles.serviceAccordian
                        }
                      >
                        <div>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1em",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  minWidth: "50px",
                                  minHeight: "35px",
                                }}
                              >
                                {res.icon !== null ? (
                                  <img
                                    src={res.icon}
                                    style={{ height: "35px", width: "35px" }}
                                    alt="service icon"
                                  />
                                ) : (
                                  <p className={styles.circledropdown} />
                                )}
                              </div>
                              <div
                                style={{
                                  fontSize: `${
                                    res?.title_px ? res?.title_px : 15
                                  }px`,
                                  marginBottom: "3px",
                                }}
                                dangerouslySetInnerHTML={{ __html: res?.title }}
                              ></div>
                            </div>
                          </AccordionSummary>
                        </div>
                        {!(
                          (!res?.desc || res?.desc === "") &&
                          res?.sub_categories.length === 0
                        ) && (
                          <AccordionDetails sx={{ paddingTop: "0" }}>
                            <div style={{ marginLeft: "calc(50px + 1em)" }}>
                              {res?.desc != "" && (
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "2em",
                                    alignItems: "flex-start",
                                    gap: "1em",
                                    height: "7rem",
                                    overflowY: "auto",
                                    textAlign: "justify",
                                    // lineHeight: "normal",
                                    padding: "10px",
                                    
                                    
                                    
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: `${
                                        res?.desc_px ? res?.desc_px : 12
                                      }px`,
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: res?.desc,
                                    }}
                                  ></p>
                                  {res?.additional_images.length != 0 && (
                                    <img
                                      src={res?.additional_images[0]?.image}
                                      style={{ height: "70px", width: "70px" }}
                                      alt="description"
                                    />
                                  )}
                                </div>
                              )}
                              {res?.sub_categories?.map((item, key) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: "1em",
                                    width: "100%",
                                    marginBottom: "1em",
                                  }}
                                  key={key}
                                >
                                  {item.icon !== null ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        minWidth: "30px",
                                        minHeight: "30px",
                                        margin: "5px",
                                      }}
                                    >
                                      <img
                                        src={item?.icon}
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        minWidth: "30px",
                                        minHeight: "30px",
                                        margin: "5px",
                                      }}
                                    >
                                      <p className={styles.circledropdown} />
                                    </div>
                                  )}
                                  <div>
                                    <div
                                      style={{
                                        fontSize: `${
                                          item?.title_px ? item?.title_px : 15
                                        }px`,
                                        marginTop:'8px'
                                        // wordBreak: "break-all",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: item?.title,
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        fontSize: `${
                                          item?.desc_px ? item?.desc_px : 15
                                        }px`,
                                        marginTop: "0.7em",
                                        height: "7rem",
                                        overflowY: "auto",
                                        textAlign: "justify",
                                        padding: "10px",
                                        // wordBreak: "break-all",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: item?.desc,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </AccordionDetails>
                        )}
                      </Accordion>
                    </div>
                  );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Specialities;
