// import logo from './logo.svg';
import { BrowserRouter, Switch } from "react-router-dom";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import "./App.css";

import React, { useEffect } from "react";
import data from "../src/data.json";

import DashboardNew from "./Patient-Web-DashBoard/DashboardNew";
// import Dashboard from './component/Dashboard';

import FullViewArticle from "./component/Articles/FullViewArticle";
import InterfaceDetails from "./hooks/InterfaceDetails";

function App() {

 

  useEffect(() => {
    getInterfaceId();

    // document.title=sessionStorage.getItem('doctor_title');
  }, []);

  const getInterfaceId = async () => {
    const formatData = data;
    sessionStorage.setItem("interface_id", formatData.data.interface_id);
    
  };

  return (
    <>
      <InterfaceDetails>
        <BrowserRouter>
          <GuardProvider>
            <Switch>
              <GuardedRoute exact path="/" component={DashboardNew} />
              <GuardedRoute
                path="/article/:id"
                exact
                component={FullViewArticle}
              />
            </Switch>
          </GuardProvider>
        </BrowserRouter>
      </InterfaceDetails>
    </>
  );
}

export default App;
