import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";

import 'react-multi-carousel/lib/styles.css';
import getWindowDimensions from "../../hooks/getWindowDimensions";
import InterfaceDetails, { useInterfaceContext } from "../../hooks/InterfaceDetails";
import { themeColor } from "../../Utils/Utils";
import { Modal } from "react-responsive-modal";
import Testimonial from "./Testimonial.js"

const Testimonials = ({ sectionData, ondisplayName }) => {
  const interfaceDetails = useInterfaceContext();


  const [home] = interfaceDetails?.data?.filter(
    (section) => section.section_id === "home"
  );

  


  const [size, setSize] = useState('');
  const desktopView = sectionData?.testimonials.length > 3;
  
  const demo = useRef(null);
  useEffect(() => {
    testimonial();
  });





  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const testimonial = () => {
    if (sectionData?.testimonials.length < 4) {
      setSize('desktop');
    }

  
  }
  const inputDivStyle = {
    border: "1px solid",
    borderColor: home?.home_theme_color,
    color: home?.home_theme_color,
    fontSize: "13px",
    display: "flex",
    fontWeight: "600",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "white",
  };
  const [open, setOpen] = useState(false);
  const [testimonialData, setTestimonialData] = useState();
  const onCloseModal = () => {
    setOpen(false);
  };
  const openTestimonialData = (data) => {
    setTestimonialData(data);
    setOpen(true);
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: sectionData?.testimonials.length <3 ? sectionData?.testimonials.length : 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 300 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
      // partialVisibilityGutter: 30 
    }
  };




  return (
    <>


      {sectionData?.testimonial_section_show_title !== 0 && sectionData?.testimonial_section_show_title !== null && (
        <div className="section-title">
          <h2 style={{ color: home?.home_theme_color,  fontFamily: home?.home_font_family }}>{sectionData?.testimonial_section_title ? sectionData?.testimonial_section_title : "Testimonial"}</h2>
        </div>
      )}

      {sectionData?.testimonials &&
        <div className="test_video">
          <Carousel
            swipeable={true}
            draggable={true}
            // showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={windowDimensions.width > 728 ? sectionData?.testimonials.length > 3 : sectionData?.testimonials.length > 1 }
            // autoPlay={true}
            autoPlaySpeed={1500}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[size, "tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px">
            {sectionData?.testimonials?.map((item, index) => (
              <>

                {/* <div className=" d-flex justify-content-center">
                  <div className="testimonials1" >
                    <div className="testimonials" >
                      <div>
                        <div className="swiper-slide">
                          <div className="testimonial-wrap">
                            <div className="testimonial-item">
                              <h1 className="testimonial-name">{item?.full_name}</h1>
                              <p ref={testimonialDescRef} style={{whiteSpace:'pre-wrap', height : "8rem", position : 'relative', overflow : 'hidden'}}>
                                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                {item?.testimonial}

                                {showReadMore && <span style={{position : 'absolute' , bottom : 0 , right : '15px'}}>...</span>}
                                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                              </p>
                              <div
                              style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        
                        <div
                        onClick={(e) => openTestimonialData(testimonial)}
                        onMouseOver={(e) => {
                          let button = document.getElementById(
                            `viewMore${testimonial.id}`
                          );
                          button.style.backgroundColor = home?.home_theme_color;
                          button.style.color = "#fff";
                        }}
                          style={{
                            width: "30%",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          
                          <button
                            type="button"
                            className="btn"
                            onClick={(e) => openTestimonialData(testimonial)}
                            id={`viewMore${testimonial.id}`}
                            style={inputDivStyle}
                            
                          >
                            Read More
                          </button>
                          </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  
                </div> */}

                <Testimonial item={item} themeColor= {home?.home_theme_color} />

                
              </>
            ))}
          </Carousel>
              
        </div>
      }
 


    </>
  )

}


export default Testimonials;