import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./FrequentlyAskedQuestion.module.css";
//import * as React from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useInterfaceContext } from "../../hooks/InterfaceDetails";

const FrequentlyAskedQuestion = ({ sectionData }) => {
  const interfaceDetails = useInterfaceContext();

  const [faqList, setFaqList] = useState(sectionData.faqs || []);
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [home] = interfaceDetails.data.filter(
    (section) => section.section_id === "home"
  );

  // const [{ name: defaultTitle }] = interfaceDetails.menu.filter(
  //   (section) => section.section_id === "faq"
  // );

  useEffect(() => {
    setFaqList(sectionData.faqs);
  }, []);

  return (
    <>
      <div className="my-5 ">
        {sectionData?.faq_section_show_title === 1 ? (
          <div class="section-title">
            <h2
              style={{
                color: home?.home_theme_color,
                // fontSize: "32px",
                fontWeight: 700,
                fontFamily: home?.home_font_family,
              }}
            >
              {sectionData?.faq_section_title || "Frequently Asked Questions"}
            </h2>
          </div>
        ) : null}
        {faqList.map((faq) => (
          <div key={faq?.id} className={styles.FrequentlyAskedQuestion}>
            <div className={styles.FrequentlyAskedQuestion_container}>
              <Accordion
                expanded={expanded === `panel${faq?.id}`}
                onChange={handleChange(`panel${faq?.id}`)}
                className={
                  expanded !== `panel${faq?.id}` && styles.faqAccordian
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id={`panel${faq?.id}bh-header`}
                  className={
                    expanded === `panel${faq?.id}` &&
                    styles.faqAccordianExpanded
                  }
                  style={{fontSize:sectionData?.faq_question_px+'px'}}
                >
                  {/* <Typography
                    sx={{
                      width: "100%",
                      flexShrink: 0,
                    }}
                  > */}
                  {faq?.faq_question}
                  {/* </Typography> */}
                </AccordionSummary>

                <AccordionDetails>
                  <Typography  style={{fontSize:sectionData?.faq_answer_px+'px'}}
                    dangerouslySetInnerHTML={{ __html: faq?.faq_answer }}
                  ></Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FrequentlyAskedQuestion;
