import React, { useState } from "react";
import { useInterfaceContext } from "../../hooks/InterfaceDetails";
import { groupByN } from "../../Utils/Utils";
import styles from "./achievements.module.css";
import clip from "text-clipper";
import Modal from "react-responsive-modal";

const Achievements = ({ sectionData }) => {
  const interfaceDetails = useInterfaceContext();

  const [home] = interfaceDetails.data.filter(
    (section) => section.section_id === "home"
  );

  const [selectedId, setSelectedId] = useState(0);

  const [modalData] = sectionData?.info?.filter(
    (section) => section.id === selectedId
  );

  const [open, setOpen] = useState(false);
  const openModal = (id) => {
    setSelectedId(id);
    setOpen(true);
  };
  const closeModal = () => setOpen(false);

  let charLimit = 60;

  const clipString = (string) => clip(string, charLimit);

  return (
    <>
      <div style={{ margin: "1em 0" }}>
        {sectionData.is_show_title === 1 && (
          <div className="section-title services">
            <h2
              style={{
                color: home?.home_theme_color,
                // fontSize: "32px",
                fontWeight: 700,
                fontFamily: home?.home_font_family,
              }}
            >
              {sectionData.title === "" ? "My Achievements" : sectionData.title}
            </h2>
          </div>
        )}
        {groupByN(4, sectionData?.info).map((section, key) => (
          <div
            className={styles.container}
            style={{
              backgroundColor:
                sectionData.template_id == 1 ? sectionData.bg_color : "white",
            }}
            key={key}
          >
            {section.map((achievement, key) => (
              <div
                className={styles.achievement}
                style={{
                  backgroundColor:
                    sectionData.template_id == 2
                      ? sectionData.bg_color
                      : "inherit",
                  cursor:
                    achievement.sub_title.length > charLimit
                      ? "pointer"
                      : "auto",
                }}
                onClick={() => {
                  achievement.sub_title.length > charLimit &&
                    openModal(achievement.id);
                }}
                key={key}
              >
                <img
                  className={styles.icon}
                  style={{ backgroundColor: sectionData.icon_circle_color }}
                  src={achievement.icon}
                ></img>
                <div
                  style={{
                    color: sectionData.title_color,
                    fontSize: sectionData.title_px,
                  }}
                >
                  {achievement.title}
                </div>
                <div
                  style={{
                    color: sectionData.sub_title_color,
                    fontSize: sectionData.sub_title_px,
                    flexGrow: 1,
                  }}
                >
                  {clipString(achievement.sub_title)}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <Modal
        open={open}
        onClose={closeModal}
        center
        showCloseIcon={false}
        animationDuration={500}
        styles={{
          modal: {
            backgroundColor: sectionData.bg_color,
            borderRadius: "10px",
            // maxWidth: "500px",
          },
        }}
      >
        <div className={styles.achievement}>
          <img
            className={styles.icon}
            style={{ backgroundColor: sectionData.icon_circle_color }}
            src={modalData?.icon}
          ></img>
          <div
            style={{
              color: sectionData.title_color,
              fontSize: sectionData.title_px,
            }}
          >
            {modalData?.title}
          </div>
          <div
            style={{
              color: sectionData.sub_title_color,
              fontSize: sectionData.sub_title_px,
              flexGrow: 1,
            }}
          >
            {modalData?.sub_title}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Achievements;
