import { useState, useEffect, useRef, useCallback } from "react";
import styles from "./articlesComponent.module.css";
import { getArticleUrl } from "../../Utils/Utils";
import "react-responsive-modal/styles.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { Modal } from "react-responsive-modal";
import FullViewArticle from "./FullViewArticle";
// import ReactPlayer from 'react-player';
// import ReactPlayer from 'react-player/lazy';
// import ReactPlayer from 'react-player/youtube';
// import { VideoProgress } from 'react-video-progress'
import ReactPlayer from "react-player";
import getWindowDimensions from "../../hooks/getWindowDimensions";
const Article = ({
  openFullViewArticle,
  resp,
  pauseOtherVideos,
  setPauseOtherVideos,
}) => {
  const [videoPlaying, setVideoPlaying] = useState(false);

  const articleContainer = useRef(null);

  const headers = {
    "App-Origin": sessionStorage.getItem("interface_id"),
  };

  const changleColor = (e, textColor, backgroundColor) => {
    articleContainer.current.style.color = textColor;
    articleContainer.current.style.backgroundColor = backgroundColor;
  };

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (videoPlaying) {
      setVideoPlaying(false);
    }
  }, [pauseOtherVideos]);

  const articleTitle = (string) => {
    var count = 30;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 30) +
        (string.length > count ? "..." : "")
      );
  };
  const articleDiscripition = (string) => {
    var count = 80;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 80) +
        (string.length > count ? "..." : "")
      );
  };

  const handlePlay = () => {
    setPauseOtherVideos((prev) => !prev);
    setVideoPlaying(true);
  };

  const handlePause = () => {
    setVideoPlaying(false);
  };
  const decodeEntities = (function () {
    const element = document.createElement("textarea");
    function decodeHTMLEntities(str) {
      if (str && typeof str === "string") {
        // str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.value;
        element.innerHtml = "";
      }
      return str;
    }

    return decodeHTMLEntities;
  })();
  return (
    <>
      <div className="col-lg-4 col-md-6  align-items-stretch">
        <div key={resp.content_desc}>
          <div
            className="article_container"
            onClick={(e) => {
              console.log(videoPlaying);
              setVideoPlaying(false);
              openFullViewArticle(resp);
            }}
            ref={articleContainer}
            // class={styles.articleContainer}
            style={{ color: "#000000", cursor: "pointer" }}
            // onMouseOver={(e) => changleColor(e, "white", "#000000")}
            // onMouseOut={(e) => changleColor(e, "#000000", "white")}
          >
            <div>
              <div
                className="article-title"
                dangerouslySetInnerHTML={{
                  __html: decodeEntities(resp.content_title),
                }}
              >
                {/* {ReactHtmlParser(resp.content_title)} */}
              </div>
              <div
                className="article-discription"
                dangerouslySetInnerHTML={{
                  __html: decodeEntities(resp.content_desc),
                }}
              >
                {/* { ReactHtmlParser(resp.content_desc) } */}

                {/* dangerouslySetInnerHTML={{ __html: resp.content_desc }} */}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="article-author">{resp.user}</div>
                <div className="article-discription">
                  <p className="cursor-pointer">
                    <u>Read More</u>{" "}
                  </p>
                </div>
              </div>
            </div>

            {resp.content_type === "pdf" && (
              <>
                {windowDimensions.width < 768 ? (
                  <div className="iframe-loader" style={{ height: "250px" }}>
                    <iframe
                      id="iframeContainer"
                      src={`https://drive.google.com/viewerng/viewer?url=${resp?.final_link}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                      width="100%"
                      height="250px"
                    />
                  </div>
                ) : (
                  <div className="article_pdf">
                    <iframe src={resp?.final_link} height="250" />
                  </div>
                )}
              </>
            )}

            <>
              {resp.content_type === "article" && (
                <div>
                  {resp?.final_link !== "" && (
                    <img
                      src={resp?.final_link}
                      alt=""
                      style={{ height: "250px", width: "100%" }}
                    />
                  )}

                  {resp?.final_link == "" && (
                    <p
                      className="article_text"
                      dangerouslySetInnerHTML={{
                        __html: decodeEntities(resp.content_value),
                      }}
                    ></p>
                  )}
                </div>
              )}
            </>
            {resp.content_type === "video" && (
              <ReactPlayer
                url={resp?.final_link}
                height="250px"
                playing={videoPlaying}
                width="99%"
                controls
                onPlay={handlePlay}
                onPause={handlePause}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Article;
