export const getTime = (timestamp) => {
    const date = new Date(timestamp);
    let minutes = date.getMinutes();
    let hours = date.getHours();
    const am_pm = hours >= 12 ? "PM" : "AM";
  
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    hours = hours > 12 ? hours - 12 : hours;
    return `${hours}:${minutes} ${am_pm}`;
  };

  export const getUrl = () => {
    //  return "https://patientseed.whitecoats.com/api/v2/";
  };
  export const getArticleUrl = () => {
    // return "https://patientseed.whitecoats.com/api/v1/";
 };

  export const headers = () => {
    let  headers = {
      "App-Origin": sessionStorage.getItem("interface_id"),
      "Content-Type": "application/json"
  };
    return headers;
    
  };
  
  export const themeColor=()=>{
    let color=sessionStorage.getItem("theme_color");
    return color;
   }
   export const font=()=>{
    let font=sessionStorage.getItem("font");
    return font;
   }
   export const logo=()=>{
    let logoUrl=sessionStorage.getItem("home_logo_url");
    return logoUrl;
   }
  export const getInterfaceID = () =>
  sessionStorage.getItem("interface_id") || sessionStorage.getItem("interface_id");

  export const groupByN = (n, data) => {
    let result = [];
    for (let i = 0; i < data.length; i += n) result.push(data.slice(i, i + n));
    return result;
  };

  export const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3],16)}` //prettier-ignore
      : null;
  };
  
   
