import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
// import Footer from "../component/Footer/Footer";
import * as Scroll from "react-scroll";
import Achievements from "../component/Achievements/Achievements";
import Articles from "../component/Articles/Articles";
import DoctorsList from "../component/Doctors/DoctorsList";
import AboutProfile from "../component/Profile/AboutProfile/Profile/AboutProfile";
import ProfileBanner from "../component/Profile/ProfileBanner/Profile/ProfileBanner";
import Specialities from "../component/Specialities/Specialities";
import Stats from "../component/Stats/Stats";
import WorkingHours from "../component/WorkingHours/WorkingHours";
import { useInterfaceContext } from "../hooks/InterfaceDetails";
import Footer from "./Footer";
import Images from "../component/Gallery/Images";
import Videos from "../component/Gallery/Videos";
import Testimonials from "../component/Testimonials/Testimonials";
import { themeColor } from "../Utils/Utils";
import Gallery from "../component/Gallery/Gallery";
import ContactUs from "../component/ContactUs/ContactUs";
import FrequentlyAskedQuestion from "../component/FrequentlyAskedQuestion/FrequentlyAskedQuestion";
import GoogleMaps from "../component/GoogleMaps/GoogleMaps";
import { ToastProvider } from "react-toast-notifications";
import AppSectionDeatils from "../component/AppSection/AppSection";



let scroll = Scroll.animateScroll;

const DashboardNew = () => {
  const interfaceDetails = useInterfaceContext();
  const [sectionData, setSectionData] = useState([]);

  const scrollTopButton = useRef(null);
  
 

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 100) {
        scrollTopButton?.current?.classList?.add("active");
      } else {
        scrollTopButton?.current?.classList?.remove("active");
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);


 
  useEffect(() => {
    // console.log(sectionData, interfaceDetails);

    if (interfaceDetails) setSectionData(interfaceDetails.data);
    sessionStorage.setItem("font", interfaceDetails?.data[0]?.home_font_family);
    sessionStorage.setItem(
      "theme_color",
      interfaceDetails?.data[0]?.theme_color
    );

    //eslint-disable-next-line
  }, [interfaceDetails]);

  const scrollToTop = () => {
    scroll.scrollToTop({
      smooth: "linear",
      duration: 0,
    });
  };

  return (
    <div className="dashboard_container">
      {sectionData.map((section, key) => {
        let component = components[section?.section_id];
        if (component)
          return (
            <React.Fragment key={key}>{component(section)}</React.Fragment>
          );

        return null;
      })}
      <button
        style={{
          background: `${interfaceDetails?.data[0]?.home_theme_color}`,
          border: "none",
          cursor: "pointer",
        }}
        ref={scrollTopButton}
        className="back-to-top d-flex align-items-center justify-content-center"
        onClick={scrollToTop}
      >
        <i className="bi bi-arrow-up-short" style={{ cursor: "pointer" }}></i>
      </button>
    </div>
  );
};

const components = {
  home: (section, ondisplayNameHandler, setMenu) => (
    <>
      <section id={section.section_id}>
        <Header
          // doctorData={interfaceDetails}
          ondisplayName={ondisplayNameHandler}
          href={setMenu}
        />
        {/* <section
          id="hero"
          style={{
            backgroundImage: `url(${section.home_banner_image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            backgroundPosition: "top ",
          }}
          className="d-flex align-items-center "
        > */}
        <ProfileBanner />
        {/* </section> */}
      </section>
      <section className="container d-block d-lg-none d-md-none force-black-text ">
        <p
          dangerouslySetInnerHTML={{
            __html: section?.home_banner_heading,
          }}
          style={{
            fontSize: `${section?.home_banner_heading_px}px`,
            textAlign: "left",
            color: "black",
          }}
        ></p>
        <p
          dangerouslySetInnerHTML={{
            __html: section?.home_banner_sub_heading,
          }}
          style={{
            fontSize: `${section?.home_banner_sub_heading_px}px `,
            textAlign: "left",
            marginBottom: "20px",
            color: "black",
          }}
        ></p>
      </section>
    </>
  ),

  // backgroundImage: `url(${bannerUrl?.home_banner_image})`,

  articles: (sectionData) => (
    <>
      <section id={sectionData.section_id} style={{ padding: "0px" }}>
        <Articles sectionData={sectionData} />
      </section>
    </>
  ),

  stats: (sectionData) => (
    <section id={sectionData.section_id}>
      <Stats sectionData={sectionData} />
    </section>
  ),
  working_hours: (sectionData) => (
    <section id={sectionData.section_id}>
      <WorkingHours sectionData={sectionData} />
    </section>
  ),

  service: (sectionData) => (
    <>
      {/* {sectionData?.service_section_status !== 0 && ( */}
      <>
        {" "}
        <section id={sectionData.section_id} className="specialities">
          {/* {sectionData?.services?.length > 0 && ( */}
          <Specialities sectionData={sectionData} />
          {/* )} */}
        </section>
      </>
      {/* )} */}
    </>
  ),

  // custom_section: (doctorsData) => (
  //   <>
  //     {doctorsData?.websiteConfigDetails?.custom_banner_message != null && (
  //       <section
  //         id="custom"
  //         className="customSection py-5 mt-5"
  //         style={{
  //           background: doctorsData?.websiteConfigDetails?.theme_color,
  //         }}
  //       >
  //         <CustomSection doctors={doctorsData} />
  //       </section>
  //     )}
  //   </>
  // ),

  about: (sectionData) => (
    <>
      {/* {sectionData?.about_section_status !== 0 && ( */}
      <section
        id={sectionData.section_id}
        className="AboutProfile pt-0 mt-5-lg mb-5"
        style={{ margin: "0 auto" }}
      >
        <div>
          <AboutProfile sectionData={sectionData} className="mt-lg-5" />
        </div>
      </section>
      {/* )} */}
    </>
  ),

  testimonial: (sectionData, ondisplayNameHandler, setMenu) => (
    // <div id={sectionData.section_id} className="section-title services">
    //   <h2>{sectionData.section_id}</h2>
    // </div>

    <section
      id={sectionData.section_id}
      style={{ padding: "0px" }}
      className="mt-lg-5"
    >
      {/* <DoctorsList sectionData={sectionData} /> */}
      <Testimonials
        sectionData={sectionData}
        ondisplayName={ondisplayNameHandler}
        href={setMenu}
      />
      <div>
          <Testimonials sectionData={sectionData} className="mt-lg-5" />
        </div>
    </section>
  ),
  achievements: (sectionData) => (
    <section id={sectionData.section_id}>
      <Achievements sectionData={sectionData} />
    </section>
  ),

  doctor: (sectionData) => (
    // <div id={sectionData.section_id} className="section-title services">
    //   <h2>{sectionData.section_id}</h2>
    // </div>

    <section id={sectionData.section_id} style={{ padding: "0px" }}>
      <DoctorsList sectionData={sectionData} />
    </section>
  ),

  gallery: (sectionData, ondisplayNameHandler, setMenu) => (
    <section id={sectionData.section_id} className="gallery mt-lg-5">
      {/* <div className="container">
        {sectionData?.gallery_section_title !== null && sectionData?.gallery_section_show_title !== 0 && (<div className="section-title">
          <h2 style={{ color: home?.home_theme_color }} >{sectionData?.gallery_section_title ? sectionData?.gallery_section_title : "Our Gallery"}</h2>
          <p>{sectionData?.gallery_section_description}</p>
        </div>)}
        <Images
          sectionData={sectionData}
          ondisplayName={ondisplayNameHandler}
          href={setMenu}
        />
        <Videos
          sectionData={sectionData}
          ondisplayName={ondisplayNameHandler}
          href={setMenu}
        />
      </div> */}
      <Gallery
        sectionData={sectionData}
        ondisplayNameHandler={ondisplayNameHandler}
        setMenu={setMenu}
      />
    </section>
  ),

  testimonial: (sectionData, ondisplayNameHandler, setMenu) => (
    <section id={sectionData.section_id} style={{ padding: "0px" }}>
      <Testimonials
        sectionData={sectionData}
        ondisplayName={ondisplayNameHandler}
        href={setMenu}
      />
    </section>
  ),

  faq: (sectionData) => (
    <section id={sectionData.section_id} style={{ padding: 0 }}>
      <FrequentlyAskedQuestion sectionData={sectionData} />
    </section>
  ),
  patientapp: (sectionData) => (
    <section id={sectionData.section_id} style={{ padding: 0 }}>
      <AppSectionDeatils
        sectionData={sectionData}/>
    </section>
  ),

  contact_us: (sectionData, ondisplayNameHandler, setMenu) => (
    <section id={sectionData.section_id} style={{ padding: "0px" }}>
      <ToastProvider>
        <ContactUs
          sectionData={sectionData}
          ondisplayName={ondisplayNameHandler}
          href={setMenu}
        />
      </ToastProvider>
    </section>
  ),

  address: (sectionData, ondisplayNameHandler, setMenu) => (
    <section id={sectionData.section_id} style={{ padding: "0px" }}>
      <GoogleMaps
        sectionData={sectionData}
        ondisplayName={ondisplayNameHandler}
        href={setMenu}
      />
    </section>
  ),

  footer: (sectionData, ondisplayNameHandler, setMenu) => (
    <section id={sectionData.section_id} style={{ padding: "0px" }}>
     
      <Footer
        sectionData={sectionData}
        ondisplayName={ondisplayNameHandler}
        href={setMenu}
      />
    </section>
  ),

};

export default DashboardNew;
