
import styles from './GoogleMaps.module.css';
import * as React from 'react';

import { useEffect } from "react";
import { useInterfaceContext } from '../../hooks/InterfaceDetails';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Address from '../Address/Address';
import "react-responsive-modal/styles.css";
import { color } from '@mui/system';


const NameContext = React.createContext();

const GoogleMaps = ({ sectionData, ondisplayName }) => {
  const interfaceDetails = useInterfaceContext();
  const [value, setValue] = React.useState(0);
  
  const test = sectionData?.addresses?.map(res => {
    // console.log("chandu" + res.address);
  })

  const [home] = interfaceDetails?.data?.filter(
    (section) => section.section_id === "home"
  );
  


  function changeMouseLeaveColor(index){
    var iconclass=document.getElementById('navTabId'+index).className;
    console.log("changeOverColor>>>>>>>>"+index);
    if(iconclass!='nav-link active'){
    document.getElementById('mapiconId'+index).style.display='none';
    document.getElementById('navTabId'+index).style.borderColor=home?.home_theme_color;

    }
  }
function changeMouseOverColor(index){
  console.log("changeOverColor>>>>>>>>"+index);
  document.getElementById('mapiconId'+index).style.display='';
  //document.getElementById('mapiconId'+index).style.opacity=0;
  document.getElementById('navTabId'+index).style.borderColor=home?.home_theme_color;

}
  function changeColor(index){ 
   console.log("changeButtoncolor>>>>>>>>"+index);
    for(var i=1;i<=3;i++){
      if(index!=i){
   // $("#navTabId"+index).css("background-color","green");
    if(document.getElementById('navTabId'+i)!=null){
    document.getElementById('navTabId'+i).style.background='white'; 
    document.getElementById('navTabId'+i).style.color=home?.home_theme_color; 
    document.getElementById('navTabId'+i).style.borderColor=home?.home_theme_color;
    document.getElementById('mapiconId'+i).style.display='none';
    }
    }else{
      if(document.getElementById('navTabId'+i)!=null){
      document.getElementById('navTabId'+i).style.background=home?.home_theme_color;
      document.getElementById('navTabId'+i).style.color='white';
      document.getElementById('mapiconId'+i).style.display='';
      } 
    }
    }
  }
  useEffect(() => {
    changeColor(1);
  }, []);
  const decodeEntities = (function () {
    const element = document.createElement("textarea");
    function decodeHTMLEntities(str) {
      if (str && typeof str === "string") {
        // str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.value;
        element.innerHtml = "";
      }
      return str;
    }

    return decodeHTMLEntities;
  })();

  
  return (sectionData?.address_section_status == 1 &&
    <>
    {sectionData?.address_section_title=='' && sectionData?.address_section_show_title==1 &&
      <div className="container">
        <div className="section-title">
          <h2 style={{color: home?.home_theme_color, fontFamily: home?.home_font_family,borderRadius: '175px'}}>Our Locations</h2>
        </div>
      </div>
    }
    {sectionData?.address_section_title!='' && sectionData?.address_section_show_title==1 &&
      <div className="container">
        <div className="section-title">
          <h2 style={{color: home?.home_theme_color, fontFamily: home?.home_font_family}}>{sectionData?.address_section_title}</h2>
        </div>
      </div>
    }
     
      
      <div className="container mt-5" > 
      <ul className="nav nav-tabs"  role="tablist">
      {sectionData?.addresses?.map((data,index) => (
        <li className="nav-item">
        <a style={{background: home?.home_theme_color,fontFamily: home?.home_font_family,marginRight:'20px',cursor:"pointer"}}  id={"navTabId"+(index+1)}  className={index==0 ? "nav-link active": "nav-link"}   data-bs-toggle="tab" href={"#addressMap"+(index+1)} onClick={() =>changeColor(index+1)}   onMouseOver={() =>changeMouseOverColor(index+1)} onMouseOut={() =>changeMouseLeaveColor(index+1)}><i className="bi bi-geo-alt" id={"mapiconId"+(index+1)} style={{display:'none',paddingRight:'3px'}}></i>Address #{index+1}</a>
        </li>
   
    ))}
     </ul>
     <div className="tab-content">
     {sectionData?.addresses?.map((data,index) => (
      <div id={"addressMap"+(index+1)}  className={index==0 ? "container tab-pane active":"container tab-pane"}>
          <div className="container">
                <div className="row mt-2">

                  <div className="col-lg-6">
                    <div className="info">
                      <div className="address" style={{marginBottom:'6px',marginTop: '15px' }}>
                      <div className="row mt-2">
                        <div className="col-auto1">
                          <div className="circletag" style={{width:'40px'}}>
                            <iconify-icon  icon="bi:geo-alt" width="29" height="25"  style={{color: home?.home_theme_color,marginTop:'6px',paddingLeft:'5px'}}></iconify-icon>
                          </div>
                        </div>
                        <div className="col-auto2" style={{width:'45px'}}>
                          <h4 style={{paddingLeft:'8px'}}><b> Location</b> </h4>
                          </div>
                        </div>
                        <div>
                        <p style={{'word-wrap': 'break-word','font-size':data.address_px,paddingLeft:'47px'}} dangerouslySetInnerHTML={{
                       __html: decodeEntities(data.address),
                        }}></p>
                        </div>
                      </div>
                      {data.contact_number!=null && data.contact_number!="" && 
                      <div className="contact-info" style={{marginBottom:'6px'}}>
                      <div className="row mt-2">
                        <div className="col-auto1">
                          <div className="circletag" style={{width:'40px'}}>
                            <iconify-icon  icon="ic:baseline-local-phone" width="29" height="25"  style={{color: home?.home_theme_color,marginTop:'6px',paddingLeft:'5px'}}></iconify-icon>
                          </div>
                        </div>
                        <div className="col-auto2" >
                          <h4 style={{paddingLeft:'8px'}}><b>Call Us On</b> </h4>
                          </div>
                        </div>
                        <div>
                        <p style={{'word-break': 'break-word','font-size':data.address_px,paddingLeft:'47px'}}><a href={`tel:${data.contact_number}`} style={{color:'black','font-size':data.address_px}}>{data.contact_number}</a></p> 
                        </div>
                      </div>
                       }
                      {data.contact_email!=null && data.contact_email!="" && 
                      <div className="contact-info" style={{marginBottom:'6px'}}>
                           <div className="row mt-2">
                        <div className="col-auto1">
                          <div className="circletag" style={{width:'40px'}}>
                            <iconify-icon  icon="carbon:email" width="29" height="25"  style={{color: home?.home_theme_color,marginTop:'6px',paddingLeft:'5px'}}></iconify-icon>
                          </div>
                        </div>
                        <div className="col-auto2" >
                          <h4 style={{paddingLeft:'8px'}}><b>Email</b> </h4>
                          </div>
                        </div>
                        <div  style={{width:'540px',height:'20px'}}>
                      <p style={{'word-break': 'break-word','font-size':data.address_px,paddingLeft:'47px'}}><a href={`mailto:${data.contact_email}`} style={{color:'black','font-size':data.address_px}}>{data.contact_email}</a></p>
                        </div>
                      </div>                   
                     
                      } 
                </div>
                     
              </div>

                  <div className="col-lg-5 mt-5 mt-lg-0">
                    <div style={{marginTop: '12px' }}>
                    <iframe width="100%" height="255px" name="gMap"src={`https://maps.google.com/maps?q=${data.address.replace(/<[^>]*>/g, '')}&t=&z=14&ie=UTF8&iwloc=&output=embed`} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    </div>

                  </div>
                </div>
              </div>
    </div>

 ))}
 
  </div>
</div>
</>
);
}


export default GoogleMaps;
