import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-scroll";
// import youtubeIcon from "../assets/footer_images/ic_youtube.png";
import { useInterfaceContext } from "../hooks/InterfaceDetails";
import { font } from "../Utils/Utils";
import QRCode from "qrcode.react";

const Footer = ({ sectionData, ondisplayName }) => {
  const interfaceDetails = useInterfaceContext();
  const [footerInfo, setFooterInfo] = useState();
  const [footerAbout, setFooterAbout] = useState();
  const [footer, setFooter] = useState(interfaceDetails?.data);
  const array = [];
  const [font_Family, setFont_Family] = useState(font);
  const footerDesc = useRef(null);

  const [isReadMore, setIsReadMore] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);

  useEffect(() => {
    setShowReadMore(isOverflown());
  }, []);

  const about_data = interfaceDetails?.data
    ?.filter((index) => index.section_id === "home")
    .map((index) => {
      return index;
    });
  const about_name = interfaceDetails?.data
    ?.filter((index) => index.section_id === "about")
    .map((index) => {
      return index;
    });

  const social_data = interfaceDetails?.data
    ?.filter((index) => index.section_id === "footer")
    .map((index) => {
      array.push(index);
      return index;
    });

  const app_data = interfaceDetails?.data
    ?.filter((index) => index.section_id === "patientapp")
    .map((index) => {
      array.push(index);
      return index;
    });
  const handleClick = (e, item) => {
    ondisplayName(item.menus_details.name);
  };

  const openLink = (index) => {
    let url = index;
    let openUrl;
    if (index.includes("www.")) {
      openUrl = url.replace("www.", "http://");
    }
    if (index.includes("https://")) {
      openUrl = url.replace("https://", "https://");
    }
    if (index.includes("http://")) {
      openUrl = url.replace("http://", "http://");
    }

    window.open(openUrl);
  };

  const isOverflown = () => {
    if (footerDesc.current)
      return (
        footerDesc.current.scrollHeight > footerDesc.current.clientHeight + 10
      );
    else return false;
  };

  const year = new Date().getFullYear();

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const blog_data = interfaceDetails?.menu
    ?.filter((index) => index.section_id === "blog")
    .map((index) => {
      array.push(index);
      return index;
    });

  return (
    <div className="" data-testid="Footer">
      <div style={{ height: "100px", backgroundColor: "white" }}></div>
      <footer>
        <div
          className="footer-top"
          style={{ background: `${about_data[0].home_theme_color}` }}
        >
          <div className="container" style={{ padding: "15px" }}>
            <div className="row">
              <div
                className="col-lg-5 col-md-6 footer-contact"
                style={{ fontFamily: `${font_Family}` }}
              >
                <h4 style={{ fontFamily: `${font_Family}` }}>About Us </h4>
                <p
                  style={{ fontSize: "19px", fontFamily: `${font_Family}` }}
                  dangerouslySetInnerHTML={{
                    __html: about_name[0]?.about_clinic_name,
                  }}
                />
                <p
                  style={{
                    fontSize: `${sectionData?.footer_desc_px}px`,
                    // wordBreak: "break-all",
                    fontFamily: `${font_Family}`,
                    height: "18rem",
                    overflow: "hidden",
                    textAlign: "justify",
                  }}
                  className="white-color-footer"
                  ref={footerDesc}
                  dangerouslySetInnerHTML={{ __html: sectionData?.footer_desc }}
                ></p>

                {showReadMore && (
                  // <div
                  //   onClick={() => {
                  //     console.log(footerDesc);
                  //     if (isReadMore) {
                  //       footerDesc.current.style.height = "18rem";
                  //       footerDesc.current.style.overflow = "hidden";
                  //     }

                  //     if (!isReadMore) {
                  //       footerDesc.current.style.overflow = "initial";
                  //       footerDesc.current.style.height = "auto";
                  //     }
                  //     setIsReadMore((prev) => !prev);
                  //   }}
                  // >
                  <Link
                    className="nav-link scrollto"
                    // activeClassName="is-active"
                    style={{ cursor: "pointer", padding: 0 }}
                    to={"about"}
                    offset={-82}
                    duration={500}
                  >
                    <div
                      style={{
                        color: "white",
                        cursor: "pointer",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        marginTop: "20px",
                      }}
                    >
                      {isReadMore ? "Read Less " : "Read More"}
                    </div>
                  </Link>
                  // </div>
                )}

                {app_data[0]?.app_footer_section == 1 &&
                  app_data[0]?.app_section_status == 1 && (
                    <p
                      style={{
                        fontSize: "19px",
                        fontFamily: `${font_Family}`,
                        height: "4px",
                        paddingTop: "10px",
                        paddingBottom: "15px",
                      }}
                    >
                      Get Our App
                    </p>
                  )}
                {app_data[0]?.app_footer_section == 1 &&
                  app_data[0]?.app_section_status == 1 && (
                    <p
                      style={{
                        textAlign: "left",
                        paddingTop: "15px",
                        display: "flex",
                      }}
                    >
                      <button
                        class="btn"
                        onClick={() =>
                          window.open(app_data[0]?.app_section_link, "_blank")
                        }
                        style={{
                          backgroundColor: "black",
                          height: "50px",
                          paddingBottom: "10px",
                          display: "flex",
                          width: "160px",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          gap: "0.6em",
                        }}
                      >
                        <img
                          width="30"
                          height="30"
                          src="assets/img/playstore-icon.png"
                          alt="google play"
                          loading="lazy"
                          data-was-processed="true"
                        />

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              color: "white",
                              fontSize: "10px",
                              maxWidth: "150px",
                            }}
                          >
                            {" "}
                            GET IT ON{" "}
                          </span>

                          <span
                            style={{
                              color: "white",
                              fontSize: "15px",
                              textAlign: "left",
                              lineBreak: "anywhere",
                            }}
                          >
                            {" "}
                            Google Play
                          </span>
                        </div>
                      </button>

                      {/* <span style={{verticalAlign:"bottom"}}><QRCode value={app_data[0]?.app_section_link} style={{marginLeft: "15px",width:"55px",height:"45px" }} /></span> */}
                    </p>
                  )}
              </div>
              <div
                className="col-lg-4 col-md-6 footer-links footer-contact"
                style={{ fontFamily: `${font_Family}` }}
              >
                <h4 style={{ fontFamily: `${font_Family}` }}>Useful Links</h4>
                <div className="d-none d-sm-block">
                  <div className="d-flex ">
                    <ul>
                      {interfaceDetails?.menu
                        .slice(0, interfaceDetails?.menu?.length / 2)
                        .map((res, key) => {
                          if (res.section_id === "footer") return null;
                          if (
                            res.section_id === "patientapp" &&
                            app_data[0]?.app_separate_section == 0
                          )
                            return null;

                          return (
                            <li key={key}>
                              <i className="bx bx-chevron-right"></i>{" "}
                              <Link
                                className="nav-link scrollto"
                                // activeClassName="is-active"
                                style={{ cursor: "pointer" }}
                                to={res.section_id}
                                offset={-82}
                                duration={500}
                                onClick={() => {
                                  if (res.section_id === "blog")
                                    openInNewTab(blog_data[0]?.link);
                                }}
                              >
                                <p style={{ fontFamily: `${font_Family}` }}>
                                  {res.name}
                                </p>
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                    <ul>
                      {interfaceDetails?.menu
                        .slice(
                          interfaceDetails?.menu?.length / 2,
                          interfaceDetails?.menu?.length + 1
                        )
                        .map((res, key) => {
                          if (res.section_id === "footer") return null;
                          if (
                            res.section_id === "patientapp" &&
                            app_data[0]?.app_separate_section == 0
                          )
                            return null;

                          return (
                            <li key={key}>
                              <i className="bx bx-chevron-right"></i>{" "}
                              <Link
                                className="nav-link scrollto"
                                // activeClassName="is-active"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (res.section_id === "blog")
                                    openInNewTab(blog_data[0]?.link);
                                }}
                                to={res.section_id}
                                offset={-82}
                                duration={500}
                              >
                                <p style={{ fontFamily: `${font_Family}` }}>
                                  {res.name}
                                </p>
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="d-flex d-block d-lg-none d-md-none">
                  <ul>
                    {interfaceDetails?.menu.map((res, key) => {
                      if (
                        res.section_id === "patientapp" &&
                        app_data[0]?.app_separate_section == 0
                      )
                        return null;
                      return (
                        <li key={key}>
                          <i className="bx bx-chevron-right"></i>{" "}
                          <Link
                            className="nav-link scrollto"
                            // activeClassName="is-active"
                            style={{ cursor: "pointer" }}
                            to={res.section_id}
                            offset={-82}
                            duration={500}
                          >
                            <p style={{ fontFamily: `${font_Family}` }}>
                              {res.name}
                            </p>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 footer-contact"
                style={{ fontFamily: `${font_Family}` }}
              >
                <>
                  <h4 style={{ fontFamily: `${font_Family}` }}>Contact</h4>

                  {array[0]?.footer_clinic_desc?.length > 13 && (
                    <>
                      <div className="footer-title">
                        <div
                          className="d-flex mb-3"
                          style={{ fontFamily: `${font_Family}` }}
                        >
                          <i
                            className="bi bi-geo-alt"
                            style={{ color: "#fff" }}
                          ></i>{" "}
                          <p
                            style={{
                              fontFamily: `${font_Family}`,
                              fontSize: `${sectionData?.footer_clinic_desc_px}px`,
                            }}
                            className="footer-title px-2 white-color-footer"
                            dangerouslySetInnerHTML={{
                              __html: sectionData?.footer_clinic_desc,
                            }}
                          />{" "}
                        </div>
                      </div>
                    </>
                  )}
                  {array[0]?.footer_desc_2?.length > 13 && (
                    <>
                      <div className="footer-title">
                        <div
                          className="d-flex mb-3"
                          style={{ fontFamily: `${font_Family}` }}
                        >
                          <i
                            className="bi bi-geo-alt"
                            style={{ color: "#fff" }}
                          ></i>{" "}
                          <p
                            style={{
                              fontFamily: `${font_Family}`,
                              fontSize: `${sectionData?.footer_desc_2_px}px`,
                              textAlign: "justify",
                            }}
                            className="footer-title px-2"
                            dangerouslySetInnerHTML={{
                              __html: sectionData?.footer_desc_2,
                            }}
                          />{" "}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="footer-title">
                    {array[0]?.footer_contact_email !== null &&
                      array[0]?.footer_contact_email !== "" && (
                        <>
                          <div className="d-flex">
                            <i
                              className="bi bi-envelope"
                              style={{ color: "#fff" }}
                            ></i>
                            &nbsp;&nbsp;&nbsp;
                            <a
                              style={{ fontFamily: `${font_Family}` }}
                              href={`mailto:${array[0]?.footer_contact_email}`}
                            >
                              <p style={{ fontFamily: `${font_Family}` }}>
                                {" "}
                                {array[0]?.footer_contact_email}
                              </p>
                            </a>
                          </div>
                          <br />
                        </>
                      )}
                  </div>

                  <div className="footer-title">
                    {array[0]?.footer_contact_email_2 &&
                      array[0]?.footer_contact_email_2 !== "" && (
                        <>
                          <div className="d-flex">
                            <i
                              className="bi bi-envelope"
                              style={{ color: "#fff" }}
                            ></i>
                            &nbsp;&nbsp;&nbsp;
                            <a
                              style={{ fontFamily: `${font_Family}` }}
                              href={`mailto:${array[0]?.footer_contact_email_2}`}
                            >
                              <p style={{ fontFamily: `${font_Family}` }}>
                                {" "}
                                {array[0]?.footer_contact_email_2}
                              </p>
                            </a>
                          </div>
                          <br />
                        </>
                      )}
                  </div>

                  {array[0]?.footer_contact_number !== null &&
                    array[0]?.footer_contact_number !== "" && (
                      <>
                        <p className="footer-title">
                          <i className="bi bi-phone"></i>&nbsp;&nbsp;&nbsp;{" "}
                          <a
                            style={{
                              fontFamily: `${font_Family}`,
                              color: "#fff",
                            }}
                            href={`tel:${array[0]?.footer_contact_number}`}
                          >
                            {array[0]?.footer_contact_number}
                          </a>
                        </p>
                        <br />
                      </>
                    )}

                  {array[0]?.footer_contact_number_2 &&
                    array[0]?.footer_contact_number_2 !== "" && (
                      <>
                        <p className="footer-title">
                          <i className="bi bi-phone"></i>&nbsp;&nbsp;&nbsp;{" "}
                          <a
                            style={{
                              fontFamily: `${font_Family}`,
                              color: "#fff",
                            }}
                            href={`tel:${array[0]?.footer_contact_number_2}`}
                          >
                            {array[0]?.footer_contact_number_2}
                          </a>
                        </p>
                        <br />
                      </>
                    )}

                  <p className="footer-title">
                    {array[0]?.footer_contact_number_3 &&
                      array[0]?.footer_contact_number_3 !== "" && (
                        <>
                          <i className="bi bi-phone"></i>&nbsp;&nbsp;&nbsp;{" "}
                          <a
                            style={{
                              fontFamily: `${font_Family}`,
                              color: "#fff",
                            }}
                            href={`tel:${array[0]?.footer_contact_number_3}`}
                          >
                            {array[0]?.footer_contact_number_3}
                          </a>
                        </>
                      )}
                  </p>
                  {/* {array[0]?.footer_clinic_desc.length>13 &&  <p className="footer-title" style={{display:'flex'}}><i className="bi bi-envelope"></i>&nbsp;&nbsp;&nbsp;<a href={`mailto:${found[0]?.footer_contact_email}`}>{found[0]?.footer_contact_email}</a></p>}
                  <br />
                  {array[0]?.footer_clinic_desc.length>13 && <p className="footer-title"><i className="bi bi-phone"></i> &nbsp;&nbsp;&nbsp;<a href={`tel:${found[0]?.footer_contact_email}`}>{found[0]?.footer_contact_email}</a></p>} 
                */}
                </>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-md-flex py-4 padding-right-desktop">
          <div
            className="me-md-auto text-center text-md-start"
            style={{ lineHeight: 3 }}
          >
            <div className="copyright d-flex">
              &copy; <span>{year}</span>
              <strong style={{ marginLeft: "5px" }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: about_name[0]?.about_clinic_name,
                  }}
                />
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits"></div>
          </div>
          <div className="social-links text-center text-md-right ">
            <div
              className="social-links text-center text-md-right pt-md-0"
              style={{
                marginBottom: "10px",
                display: "flex",
                gap: "2px",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {social_data[0]?.footer_facebook !== null &&
                social_data[0]?.footer_facebook !== "" && (
                  <button
                    style={{
                      // background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      // width: "50px",
                      height: "50px",
                    }}
                    onClick={() => openLink(social_data[0]?.footer_facebook)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_facebook.png"
                      style={{ width: "40px" }}
                    />
                  </button>
                )}
              {social_data[0]?.footer_twitter !== null &&
                social_data[0]?.footer_twitter !== "" && (
                  <button
                    style={{
                      // background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      // width: "45px",
                      height: "45px",
                    }}
                    onClick={() => openLink(social_data[0]?.footer_twitter)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_twitter.png"
                      style={{ width: "40px" }}
                    />
                  </button>
                )}
              {social_data[0]?.footer_linked_in !== null &&
                social_data[0]?.footer_linked_in !== "" && (
                  <button
                    style={{
                      // background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      // width: "45px",
                      height: "45px",
                    }}
                    onClick={() => openLink(social_data[0]?.footer_linked_in)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_linkedin.png"
                      style={{ width: "40px" }}
                    />
                  </button>
                )}
              {social_data[0]?.footer_instagram !== null &&
                social_data[0]?.footer_instagram !== "" && (
                  <button
                    style={{
                      // background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      // width: "45px",
                      height: "45px",
                    }}
                    onClick={() => openLink(social_data[0]?.footer_instagram)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_instagram.png"
                      style={{ width: "40px" }}
                    />
                  </button>
                )}
              {social_data[0]?.footer_gbp !== null &&
                social_data[0]?.footer_gbp !== "" && (
                  <button
                    style={{
                      // background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      // width: "45px",
                      height: "45px",
                    }}
                    onClick={() => openLink(social_data[0]?.footer_gbp)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_gmb.png"
                      style={{ width: "40px" }}
                    />
                  </button>
                )}
              {social_data[0]?.footer_youtube != null &&
                social_data[0]?.footer_youtube != "" && (
                  <button
                    style={{
                      // background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      // width: "45px",
                      height: "45px",
                    }}
                    onClick={() => openLink(social_data[0]?.footer_youtube)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_youtube.png"
                      style={{ width: "40px" }}
                    />
                  </button>
                )}
              {social_data[0]?.footer_whatsapp != null &&
                social_data[0]?.footer_whatsapp != "" && (
                  <button
                    style={{
                      // background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      // width: "45px",
                      height: "45px",
                    }}
                    onClick={() => openLink(social_data[0]?.footer_whatsapp)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img
                      src="assets/footer_images/ic_whatsapp.png"
                      style={{ width: "40px" }}
                    />
                  </button>
                )}
            </div>
          </div>
        </div>
      </footer>
      {/* <section>
        <div className="me-md-auto text-center text-md-start" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 60px' }}>
          <div className="container d-md-flex py-5">
            <div
              className="copyright"
              style={{ wordBreak: "break-all", fontFamily: `${font_Family}`, lineHeight: '3' }} >
              &copy; Copyright{" "}
              <strong>
                <span>WhiteCoats</span>
              </strong>
              . All Rights Reserved
            </div>
            <div
              className="social-links text-center text-md-right pt-md-0"
              style={{ marginBottom: "10px" }}
            >
              {social_data[0]?.footer_facebook !== null &&
                social_data[0]?.footer_facebook !== "" && (
                  <button
                    style={{
                      background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      width: '45px',
                      height: '45px'
                    }}
                    onClick={() => openLink(social_data[0]?.footer_facebook)}
                    type="button" className="btn btn-infoo">
                    <img src="assets/footer_images/ic_facebook_white.png" />
                  </button>
                )}
              {social_data[0]?.footer_twitter !== null &&
                social_data[0]?.footer_twitter !== "" && (
                  <button
                    style={{
                      background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      width: '45px',
                      height: '45px'
                    }}
                    onClick={() => openLink(social_data[0]?.footer_twitter)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img src="assets/footer_images/ic_twitter_white.png" />
                  </button>
                )}
              {social_data[0]?.footer_linked_in !== null &&
                social_data[0]?.footer_linked_in !== "" && (
                  <button
                    style={{
                      background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      width: '45px',
                      height: '45px'
                    }}
                    onClick={() => openLink(social_data[0]?.footer_linked_in)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img src="assets/footer_images/ic_linkedin_white.png" />
                  </button>
                )}
              {social_data[0]?.footer_instagram !== null &&
                social_data[0]?.footer_instagram !== "" && (
                  <button
                    style={{
                      background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      width: '45px',
                      height: '45px'
                    }}
                    onClick={() => openLink(social_data[0]?.footer_instagram)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img src="assets/footer_images/ic_instagram_white.png" />
                  </button>
                )}
              {social_data[0]?.footer_gbp !== null &&
                social_data[0]?.footer_gbp !== "" && (
                  <button
                    style={{
                      background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      width: '45px',
                      height: '45px'
                    }}
                    onClick={() => openLink(social_data[0]?.footer_gbp)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img src="assets/footer_images/ic_gmb_white.png" />
                  </button>
                )}
              {social_data[0]?.footer_whatsapp != null &&
                social_data[0]?.footer_whatsapp != "" && (
                  <button
                    style={{
                      background: `${about_data[0]?.home_theme_color}`,
                      borderRadius: "50%",
                      width: '45px',
                      height: '45px'

                    }}
                    onClick={() => openLink(social_data[0]?.footer_whatsapp)}
                    type="button"
                    className="btn btn-infoo"
                  >
                    <img src="assets/footer_images/ic_whatsapp_white.png" />
                  </button>
                )}


            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Footer;
