import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ReactPlayer from "react-player";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useInterfaceContext } from "../../hooks/InterfaceDetails";
import VideoThumbnail from "react-video-thumbnail";
import Video from "./Video";
import interfaceData from "../../data.json";
import Spinner from "../Spinner";

const Videos = ({ sectionData, ondisplayName, fullViewArticle }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    "App-Origin": sessionStorage.getItem("interface_id"),
    "Content-Type": "application/json",
  };
  // const { setVideoPgNO , spinner} = useInterfaceContext();
  const modalRef = useRef(null);
  const [page, setPage] = React.useState(1);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [pauseOtherVideos, setPauseOtherVideos] = useState(false);
  const [fullView, setFullView] = useState();
  const [galleryData, setGalleryData] = useState([]);
  const [spinner, setSpinner] = useState(false);

//   useEffect(() => {
//     setGalleryData(sectionData?.videos?.data);
//   }, []);

  useEffect(() => {
    getGalleryDetails();
  }, [page]);

  const handlePlay = () => {
    setPauseOtherVideos((prev) => !prev);
    setVideoPlaying(true);
  };

  const handlePause = () => {
    setVideoPlaying(false);
  };
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, value) => {
    // setVideoPgNO(value)

    setPage(value);
  };

  const handleOpen = (video) => {
    setFullView(video);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getGalleryDetails = async () => {
    const interfaceId = interfaceData.data.interface_id;
    setSpinner(true);
    const res = await fetch(
      baseUrl +
        `patient-website/get-gallery-videos?interface_id=${encodeURIComponent(
          interfaceId
        )}&videos_page=${encodeURIComponent(page)}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const data = await res.json();

    if (data.response != null) {
      const sections = data?.response?.videos;
      setGalleryData(sections);
     
      
    }
    setSpinner(false);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row no-gutters">
          {spinner ? (
            <div style={{display:'flex',justifyContent:'center'}}>
            <Spinner />
            </div>

          ) : (
            galleryData?.data?.map((item, index) => (
              <>
                {/* <div className="col-lg-4 col-md-4 mt-4">
                                <div style={{ background: '#fff', height: '320px', border: '1px solid #000' }}>
                                    <h1 className="testimonial-name" >{item?.file_title}</h1>
                                    <div className="readMore-container px-2">
                                        <p style={{ cursor: "pointer" }} onClick={(e) => handleOpen(item)}><u>Read More</u></p>
                                    </div>
                                    <div className="gallery-item">
                                        <ReactPlayer
                                            pauseOtherVideos={pauseOtherVideos}
                                            setPauseOtherVideos={setPauseOtherVideos}
                                            url={item?.file_url} playing={videoPlaying} style={{ maxHeight: '200px' }}
                                            width="99%" controls onPlay={handlePlay} onPause={handlePause} />
                                            
                                    </div>
                                </div>
                            </div> */}
                <Video
                  pauseOtherVideos={pauseOtherVideos}
                  setPauseOtherVideos={setPauseOtherVideos}
                  handleOpen={handleOpen}
                  item={item}
                />
              </>
            ))
          )}
        </div>
        {galleryData?.total > 6 && (
          <div className="pagination mt-3">
            <Stack spacing={2}>
              <Pagination
                siblingCount={0}
                count={galleryData?.last_page}
                showFirstButton
                showLastButton
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        )}
        {spinner}
      </div>
      <Modal
       classNames={{ modal: "image_model" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <h5 id="parent-modal-title" className="mx-sm-2"> {fullView?.file_title}</h5>
        <p id="parent-modal-description">
          <div className="gallery-item">
            <ReactPlayer
              pauseOtherVideos={pauseOtherVideos}
              setPauseOtherVideos={setPauseOtherVideos}
              url={fullView?.file_url}
              playing={videoPlaying}
              controls
              width={"100%"}
              className="video_play"
              onPlay={handlePlay}
              onPause={handlePause}
            />
          </div>
        </p>
       
      </Modal>
    </>
  );
};

export default Videos;
