import React from 'react';
import PropTypes from 'prop-types';
import styles from './Address.module.css';

const Address = (data) => (
  <div className={styles.Address} data-testid="Address">
    Address Component{data}
  </div>
);

Address.propTypes = {};

Address.defaultProps = {};

export default Address;
